'use client';

import { USER_ACTIVE_EVENTS } from '../constants';
import {
  deleteUserLastActiveTimeCookie,
  getUserLastActiveTimeCookie,
  setUserLastActiveTimeCookie,
} from './cookieHelpers';
import { useAuth } from './useAuth';
import { IS_BROWSER } from './utils/isBrowser';
import { throttleFn } from './utils/throttle';
import { useCallback, useEffect, useRef } from 'react';

export interface IIdleUserTimeout {
  /**
   * Restores the user to active
   */
  activate: () => void;

  /**
   * Logs out the user based on configured settings.
   */
  logout: () => void;
}

export interface IdleUserTimeoutProps {
  /**
   * The duration of idle time in milliseconds before triggering the timeout.
   *
   * @default 300_000
   * @maximum 900_000 (15 minutes)
   */
  idleTimeoutMs?: number;

  /**
   * The duration of time in milliseconds before prompting the user before the idle timeout.
   * This value must be less than idleTimeoutMs.
   *
   * @default 60_000 (1 minute)
   */
  promptBeforeIdleTimeoutMs?: number;

  /**
   * The interval duration in milliseconds for checking the idle timeout.
   *
   * @default 10_000 (10 seconds)
   */
  idleTimeoutCheckIntervalMs?: number;

  /**
   * Determines if the idle timeout functionality is disabled.
   * This value is overridden if the user is on the redirect URL within an iframe which occurs during session validity checks.
   */
  disabled: boolean;

  /**
   * Throttle in milliseconds for activity events.
   *
   * @default 2_000 (2 seconds)
   */
  eventsThrottleMs?: number;

  /**
   * Determines if the user should be redirected on logout.
   */
  redirectOnLogout: boolean;

  /**
   * The URL to redirect to after logout.
   *
   * @default OAuthClientSettings.post_logout_redirect_uri
   */
  logoutRedirectUrl?: string;

  /**
   * Callback function called when the user is prompted before the idle timeout.
   */
  onPrompt: () => void;

  /**
   * Callback function called when the shared last active cookie is updated by another tab.
   */
  onPromptCancelled: () => void;

  /**
   * Callback function called when the idle timeout has expired.
   */
  onTimerExpired?: (() => Promise<void>) | (() => void);
}

export const idleUserTimeoutDefaults = {
  idleTimeoutMs: 300_000, // 5 minutes
  promptBeforeIdleTimeoutMs: 60_000, // 1 minute
  idleTimeoutCheckIntervalMs: 10_000, // 10 seconds
  eventsThrottleMs: 2_000, // 2 seconds
};

// Per InfoSec, the idle timeout should be no longer than 15 minutes.
const maxIdleTimeoutMs = 900_000;

/**
 * Hook for managing idle user timeout functionality.
 *
 * @param props Configuration options
 * @returns IIdleUserTimeout
 */
export const useIdleUserTimeout = ({
  idleTimeoutMs = idleUserTimeoutDefaults.idleTimeoutMs,
  promptBeforeIdleTimeoutMs = idleUserTimeoutDefaults.promptBeforeIdleTimeoutMs,
  idleTimeoutCheckIntervalMs = idleUserTimeoutDefaults.idleTimeoutCheckIntervalMs,
  disabled,
  redirectOnLogout,
  eventsThrottleMs = idleUserTimeoutDefaults.eventsThrottleMs,
  logoutRedirectUrl,
  onPrompt,
  onPromptCancelled,
  onTimerExpired,
}: IdleUserTimeoutProps): IIdleUserTimeout => {
  if (idleTimeoutMs > maxIdleTimeoutMs) {
    throw new Error('idleUserTimeout exceeds the maximum allowed value');
  }
  if (promptBeforeIdleTimeoutMs >= idleTimeoutMs) {
    throw new Error(
      'promptBeforeIdleTimeoutMs must be less than idleTimeoutMs',
    );
  }

  const auth = useAuth();

  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const prompted = useRef<boolean>(false);
  const listenersRegistered = useRef<boolean>(false);

  // Set the initial last active time cookie on component load unless running within iframe.
  // Hidden iFrames are used for session validity checks and should not update the last active time.
  // If for some reason the web app is needed to be run in iFrame, the user activity will still update cookie value.
  useEffect(() => {
    const isIFrame =
      typeof window !== 'undefined' && window.self !== window.parent;
    if (disabled || isIFrame) return;
    if (auth.isAuthenticated) {
      setUserLastActiveTimeCookie(Date.now());
    } else {
      deleteUserLastActiveTimeCookie();
    }
  }, [auth.isAuthenticated, disabled]);

  const redirectOnLogoutRef = useRef(redirectOnLogout);
  const logoutRedirectUrlRef = useRef(logoutRedirectUrl);
  useEffect(() => {
    redirectOnLogoutRef.current = redirectOnLogout;
    logoutRedirectUrlRef.current = logoutRedirectUrl;
  }, [redirectOnLogout, logoutRedirectUrl]);

  const logout = useCallback(async () => {
    if (onTimerExpired) {
      await onTimerExpired();
    }
    if (redirectOnLogoutRef.current) {
      auth.signoutRedirect({
        post_logout_redirect_uri: logoutRedirectUrlRef.current,
      });
    } else {
      auth.signoutSilent();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [onTimerExpired, auth]);

  const activate = () => {
    prompted.current = false;
    setUserLastActiveTimeCookie(Date.now());
  };

  const prompt = useCallback(() => {
    // Don't call onPrompt again if we are already prompted
    if (prompted.current) return;

    prompted.current = true;
    onPrompt();
  }, [onPrompt]);

  const cancelPrompt = useCallback(() => {
    prompted.current = false;
    onPromptCancelled();
  }, [onPromptCancelled]);

  useEffect(() => {
    if (disabled) return;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (auth.isAuthenticated) {
      intervalRef.current = setInterval(() => {
        const lastActiveTime = Number(getUserLastActiveTimeCookie());

        if (Number.isNaN(lastActiveTime)) return;

        const currentTime = Date.now();
        const timeoutTime = lastActiveTime + idleTimeoutMs;
        const promptTime = timeoutTime - promptBeforeIdleTimeoutMs;

        if (currentTime < promptTime && prompted.current) {
          // Cancel the prompt if already prompted and the last active time is now before the prompt time.
          // This will occur if another tab had activity.
          cancelPrompt();
        }

        if (currentTime >= promptTime && currentTime < timeoutTime) {
          prompt();
        } else if (currentTime >= timeoutTime) {
          logout();
        }
      }, idleTimeoutCheckIntervalMs);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [
    auth.isAuthenticated,
    disabled,
    idleTimeoutCheckIntervalMs,
    idleTimeoutMs,
    logout,
    prompt,
    cancelPrompt,
    promptBeforeIdleTimeoutMs,
    onTimerExpired,
  ]);

  const activeEventHandler = () => {
    // Do not update last active if the user is prompted
    // we want them to be able to interact with the prompt
    if (prompted.current) return;

    setUserLastActiveTimeCookie(Date.now());
  };

  // Setup event listeners
  const handleActiveEvents = useRef(
    throttleFn(activeEventHandler, eventsThrottleMs),
  );

  const addEventListeners = () => {
    if (!IS_BROWSER) return;
    if (!listenersRegistered.current) {
      USER_ACTIVE_EVENTS.forEach((e) => {
        document.addEventListener(e, handleActiveEvents.current, {
          capture: true,
          passive: true,
        });
      });
      listenersRegistered.current = true;
    }
  };

  const removeEventListeners = () => {
    if (!IS_BROWSER) return;
    if (listenersRegistered.current) {
      USER_ACTIVE_EVENTS.forEach((e) => {
        document.removeEventListener(e, handleActiveEvents.current, {
          capture: true,
        });
      });
      listenersRegistered.current = false;
    }
  };

  useEffect(() => {
    if (listenersRegistered.current) removeEventListeners();
    if (disabled) return;
    if (!auth.isAuthenticated) return;

    handleActiveEvents.current = throttleFn(
      activeEventHandler,
      eventsThrottleMs,
    );

    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, [auth.isAuthenticated, disabled, eventsThrottleMs]);

  const idleUserTimeout = {
    activate,
    logout,
  };

  return idleUserTimeout;
};
