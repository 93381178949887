'use client';

import { TrackingEvent } from './types/events';
import { ReactElement, createContext, useContext } from 'react';
import { useTracking } from 'react-tracking';

const AnalyticsContext = createContext<
  { dispatch: (data: TrackingEvent) => void } | undefined
>(undefined);

type AnalyticsProviderProps = {
  children: ReactElement | ReactElement[];
  dispatch: (data: TrackingEvent) => void;
};

/**
 * Provides an Analytics context to its children.
 *
 * @param props - The props for the AnalyticsProvider.
 * @param props.children - The children components.
 * @param props.dispatch - The dispatch function for tracking events.
 * @returns - The provider component.
 */
export function AnalyticsProvider({
  children,
  dispatch,
}: AnalyticsProviderProps) {
  return (
    <AnalyticsContext.Provider value={{ dispatch }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

/**
 * Use analytics tracking.
 *
 * @param data - The data to be tracked.
 *   Accept 'any' for data to allow ad hoc data enrichment.
 *   React tracing will union data with the event object
 * @returns - The tracking function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAnalyticsTracking(data: any = {}) {
  const context = useContext(AnalyticsContext);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return useTracking<TrackingEvent>(data, {
    dispatch: context?.dispatch,
  });
}
