// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FnType = (...args: any[]) => any;

export function throttleFn<T extends FnType>(fn: T, delay: number): T {
  let lastCall = 0;
  return function (...args: Parameters<T>): ReturnType<T> | undefined {
    const now = Date.now();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(args);
  } as T;
}
