'use client';

import {
  Tracking,
  VideoEndedEvent,
  VideoEndedPayload,
} from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackVideoEnded = ReturnType<typeof useTrackVideoEnded>;

/**
 * Custom React hook for tracking video ended events.
 */
export const useTrackVideoEnded = () => {
  const { trackEvent } = useTracking<VideoEndedEvent>({
    eventType: Tracking.videoEnded,
  });

  return (eventInfo: VideoEndedPayload['eventInfo']): void => {
    trackEvent({
      eventInfo,
    });
  };
};
