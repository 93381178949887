'use client';

import { LoginEvent, Tracking } from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackLogin = ReturnType<typeof useTrackLogin>;

/**
 * Custom React hook for tracking login events.
 */
export const useTrackLogin = () => {
  const { trackEvent } = useTracking<LoginEvent>({
    eventType: Tracking.webLogin,
  });

  return (eventInfo: { sourceURL: string }): void => {
    trackEvent({
      eventInfo,
    });
  };
};
