'use client';

/**
 * Login component This component is used to initiate a login process and should
 * be used as the returned component from a login page.
 *
 * During an SSR render, if it is determined the user is not authenticated and
 * authentication is required, the SSR page should redirect the user to the
 * login page route and include a `returnTo` query param to allow for deep
 * linking.
 *
 * This component is only used to initiate the login flow.
 */
import { setPostLoginReturnToCookie } from './cookieHelpers';
import { useAuth } from './useAuth';
import { useEffect } from 'react';

export const Login = () => {
  const { signinRedirect } = useAuth();

  useEffect(() => {
    if (window?.location?.search) {
      const returnTo = new URLSearchParams(window.location.search).get(
        'returnTo',
      );
      if (returnTo) {
        setPostLoginReturnToCookie(returnTo);
      }
    }
    signinRedirect();
  }, [signinRedirect]);

  return null;
};
