'use client';

import {
  SearchPayloadEventInfo,
  SearchSubmitSuccessEvent,
  Tracking,
} from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackSearchSubmitSuccess = ReturnType<
  typeof useTrackSearchSubmitSuccess
>;

/**
 * Custom React hook for tracking search submit success events.
 */
export const useTrackSearchSubmitSuccess = () => {
  const { trackEvent } = useTracking<SearchSubmitSuccessEvent>({
    eventType: Tracking.searchSubmitSuccess,
  });

  return (eventInfo: SearchPayloadEventInfo): void => {
    trackEvent({
      eventInfo,
    });
  };
};
