'use client';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

/**
 * A custom React hook that returns the current URL of the page and then
 *   extracts the URL from it.
 * It updates the URL state whenever the location changes.
 *
 * @returns - The current URL of the page, or undefined if not available.
 */
export const useLocationUrl = (): string | undefined => {
  const location = useLocation();
  const [url, setUrl] = useState<string | undefined>();
  useEffect(() => {
    setUrl(location.href);
  }, [location.href]);
  return url;
};
