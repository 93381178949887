import type { OAuthClientSettings } from '@thrivent-web/authentication';

const { origin: windowForgerockBaseOrigin } = new URL(
  process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL'] ?? '/',
  'http://localhost',
);
const isEphemeral =
  windowForgerockBaseOrigin ===
  'https://oauth-redirect-handler-main.apps.dev.thrivent.com';

const windowUrl =
  typeof window !== 'undefined'
    ? isEphemeral
      ? windowForgerockBaseOrigin
      : window?.location.protocol + '//' + window?.location.host
    : undefined;

const NEXT_PUBLIC_FORGEROCK_BASE_URL = windowUrl
  ? (process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL'] ??
    'NEXT_PUBLIC_FORGEROCK_BASE_URL_NOT_SET')
  : (process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL_SSR'] ??
    'NEXT_PUBLIC_FORGEROCK_BASE_URL_SSR_NOT_SET');

const NEXT_PUBLIC_FORGEROCK_ISSUER =
  process.env['NEXT_PUBLIC_FORGEROCK_ISSUER'] ??
  'NEXT_PUBLIC_FORGEROCK_ISSUER_NOT_SET';

const NEXT_PUBLIC_FORGEROCK_REDIRECT_URI = windowUrl
  ? windowUrl + '/authentication/success'
  : (process.env['NEXT_PUBLIC_FORGEROCK_REDIRECT_URI_SSR'] ??
    'NEXT_PUBLIC_FORGEROCK_REDIRECT_URI_SSR_NOT_SET');

const NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI = windowUrl
  ? (process.env['NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI'] ??
      'NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI_NOT_SET') +
    '?end_url=' +
    windowUrl
  : (process.env['NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI_SSR'] ??
    'NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI_SSR_NOT_SET');

const NEXT_PUBLIC_FORGEROCK_SCOPES =
  process.env['NEXT_PUBLIC_FORGEROCK_SCOPES'] ??
  'NEXT_PUBLIC_FORGEROCK_SCOPES_NOT_SET';

const NEXT_PUBLIC_FORGEROCK_CLIENT_ID =
  process.env['NEXT_PUBLIC_FORGEROCK_CLIENT_ID'] ??
  'NEXT_PUBLIC_FORGEROCK_CLIENT_ID_NOT_SET';

export const oidcClientSettings: OAuthClientSettings = {
  client_id: NEXT_PUBLIC_FORGEROCK_CLIENT_ID,
  scope: NEXT_PUBLIC_FORGEROCK_SCOPES,
  redirect_uri: NEXT_PUBLIC_FORGEROCK_REDIRECT_URI,
  post_logout_redirect_uri: NEXT_PUBLIC_FORGEROCK_POST_LOGOUT_REDIRECT_URI,
  authority: NEXT_PUBLIC_FORGEROCK_ISSUER,
  metadata: {
    authorization_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/authorize`,
    token_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/access_token`,
    userinfo_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/userinfo`,
    end_session_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/connect/endSession`,
    revocation_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/token/revoke`,
    check_session_iframe: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/connect/checkSession`,
    issuer: NEXT_PUBLIC_FORGEROCK_ISSUER,
  },
};
