'use client';

import { SessionMonitor } from '@thrivent-web/authentication';
import { usePathname } from 'next/navigation';

const { origin: windowForgerockBaseOrigin } = new URL(
  process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL'] ?? '/',
  'http://localhost',
);
const isEphemeral =
  windowForgerockBaseOrigin ===
  'https://oauth-redirect-handler-main.apps.dev.thrivent.com';

const windowUrl =
  typeof window !== 'undefined'
    ? isEphemeral
      ? windowForgerockBaseOrigin
      : window?.location.protocol + '//' + window?.location.host
    : undefined;

const redirectUri = windowUrl
  ? windowUrl + '/authentication/callback'
  : (process.env['NEXT_PUBLIC_FORGEROCK_SILENT_REDIRECT_URI_SSR'] ??
    'NEXT_PUBLIC_FORGEROCK_SILENT_REDIRECT_URI_SSR_NOT_SET');

export const AuthSessionMonitor = () => {
  const pathname = usePathname() ?? '';
  return (
    <SessionMonitor
      signinArgs={{
        redirect_uri: redirectUri,
      }}
      disabled={pathname.startsWith('/authentication')}
      redirectOnEndSession={false}
    />
  );
};
