export enum Tracking {
  pageView = 'PageView',
  webLogin = 'WebLogin',
  /** @deprecated - Pushing a click event is no longer necessary, use one of these options instead:
   * 1. turn on user interactions and annotate your clicked elements with data-dd-action-name
   * 2. push a custom event with datadogRum.addAction
   *
   * Consult #help-analytics for more details.
   */
  click = 'Click',
  formSubmitAttempt = 'FormSubmitAttempt',
  formSubmitSuccess = 'FormSubmitSuccess',
  delegateAccessSubmitError = 'DelegateAccessSubmitError',
  searchSubmitSuccess = 'SearchSubmitSuccess',
  videoPlay = 'VideoPlay',
  videoLoad = 'VideoLoad',
  videoEnded = 'VideoEnded',
  videoVolumeChanged = 'VideoVolumeChanged',
  videoPause = 'VideoPause',
  videoDuration = 'VideoDuration',
  notification = 'Notification',
  advisorLogin = 'AdvisorLogin',
  mobileLogin = 'MobileLogin',
  callToAction = 'CallToAction',
  modalPop = 'ModalPop',
  bannerPop = 'BannerPop',
  chatStart = 'ChatStart',
  chatEnd = 'ChatEnd',
  chatClose = 'ChatClose',
  chatOpen = 'ChatOpen',
  chatMaximize = 'ChatMaximize',
  chatMinimize = 'ChatMinimize',
}

type TrackingEventBase<T extends Tracking> = {
  eventType: T;
};

export type WebPageDetails = {
  URL?: string;
  isErrorPage?: boolean;
  isHomePage?: boolean;
  name?: string;
  subChannel?: string;
  applicationName?: string;
  dynamicItems?: {
    type: 'article' | 'card' | 'tool';
    name?: string;
  }[];
};

type InteractionEventPayload = {
  productType?: string;
  description?: string;
  position?: number;
};

type LoginPayload = {
  eventInfo?: {
    sourceURL: string;
  };
};

type AdobeWebPayload = {
  web?: {
    webPageDetails: WebPageDetails;
  };
  description?: string;
};

export type SearchPayloadEventInfo = {
  searchType?: string;
  searchTerm?: string;
  searchResultCount: number;
  searchFilter?: string;
  sortOrder?: string;
  searchFilters?: {
    advisorSpecialty?: string[];
    location?: string;
    locationRadius?: number;
  };
};

type SearchPayload = {
  eventInfo: SearchPayloadEventInfo;
};

type VideoPayload = {
  eventInfo?: {
    videoTitle: string;
    videoGUID: string;
    value?: string;
  };
};

export type VideoEndedPayload = {
  eventInfo: {
    videoTitle: string;
    videoGUID: string;
    value?: string;
  };
};

export type FormPayloadEventInfo = {
  formName: string;
  formGoal: string;
  // formId is only necessary to differentiate between multiple of the same form on the page
  formId?: string;
  formInputs?: [
    {
      fieldName: string;
      fieldValue: string | string[];
    },
  ];
  formVersion?: string;
};

type FormPayload = {
  eventInfo: FormPayloadEventInfo;
};

export type NotificationPayload = {
  eventInfo: {
    notificationName: string;
    notificationVariant: string;
    notificationId?: string;
  };
};

type AdvisorLoginPayload = {
  eventInfo: {
    advisorId: string;
  };
};

type MeetingTypePayload = {
  eventInfo: {
    formInputs: {
      meetingType: string | undefined;
    };
  };
};

type MobileLoginPayload = {
  eventInfo: {
    loginType: 'manual' | 'biometric';
  };
};

type CallToActionPayload = {
  eventInfo: {
    ctaStyle: string;
    ctaText: string;
  };
};

type ModalPopPayload = {
  eventInfo: {
    modalName: string;
  };
};

type BannerPopPayload = {
  eventInfo: {
    bannerName: string;
  };
};

type ChatPayload = {
  eventInfo: {
    duration?: number | null;
  };
};

export type PageViewEvent = TrackingEventBase<Tracking.pageView> &
  AdobeWebPayload;
export type LoginEvent = TrackingEventBase<Tracking.webLogin> & LoginPayload;
type ClickEvent = TrackingEventBase<Tracking.click> & InteractionEventPayload;
export type FormSubmissionAttemptEvent =
  TrackingEventBase<Tracking.formSubmitAttempt> & FormPayload;
export type FormSubmissionSuccessEvent =
  TrackingEventBase<Tracking.formSubmitSuccess> & FormPayload;
export type DelegateAccessErrorEvent =
  TrackingEventBase<Tracking.delegateAccessSubmitError> & NotificationPayload;
export type SearchSubmitSuccessEvent =
  TrackingEventBase<Tracking.searchSubmitSuccess> & SearchPayload;
export type VideoPlayEvent = TrackingEventBase<Tracking.videoPlay> &
  VideoPayload;
export type VideoLoadEvent = TrackingEventBase<Tracking.videoLoad> &
  VideoPayload;
export type VideoEndedEvent = TrackingEventBase<Tracking.videoEnded> &
  VideoEndedPayload;
export type VideoVolumeChangedEvent =
  TrackingEventBase<Tracking.videoVolumeChanged> & VideoPayload;
export type VideoPauseEvent = TrackingEventBase<Tracking.videoPause> &
  VideoPayload;
export type VideoDurationEvent = TrackingEventBase<Tracking.videoDuration> &
  VideoPayload;
export type NotificationEvent = TrackingEventBase<Tracking.notification> &
  NotificationPayload;
export type AdvisorLoginEvent = TrackingEventBase<Tracking.advisorLogin> &
  AdvisorLoginPayload;
export type MeetingTypeEvent = TrackingEventBase<Tracking.formSubmitSuccess> &
  MeetingTypePayload;
export type MobileLoginEvent = TrackingEventBase<Tracking.mobileLogin> &
  MobileLoginPayload;
export type CallToActionEvent = TrackingEventBase<Tracking.callToAction> &
  CallToActionPayload;
export type ModalPopEvent = TrackingEventBase<Tracking.modalPop> &
  ModalPopPayload;
export type BannerPopEvent = TrackingEventBase<Tracking.bannerPop> &
  BannerPopPayload;
export type ChatCloseEvent = TrackingEventBase<Tracking.chatClose> &
  ChatPayload;
export type ChatEndEvent = TrackingEventBase<Tracking.chatEnd> & ChatPayload;
export type ChatStartEvent = TrackingEventBase<Tracking.chatStart> &
  ChatPayload;
export type ChatOpenEvent = TrackingEventBase<Tracking.chatOpen> & ChatPayload;
export type ChatMaximizeEvent = TrackingEventBase<Tracking.chatMaximize> &
  ChatPayload;
export type ChatMinimizeEvent = TrackingEventBase<Tracking.chatMinimize> &
  ChatPayload;

export type TrackingEvent =
  | PageViewEvent
  | LoginEvent
  | FormSubmissionAttemptEvent
  | FormSubmissionSuccessEvent
  | DelegateAccessErrorEvent
  | ClickEvent
  | SearchSubmitSuccessEvent
  | VideoPlayEvent
  | VideoLoadEvent
  | VideoEndedEvent
  | VideoVolumeChangedEvent
  | VideoPauseEvent
  | VideoDurationEvent
  | NotificationEvent
  | AdvisorLoginEvent
  | MeetingTypeEvent
  | MobileLoginEvent
  | CallToActionEvent
  | ModalPopEvent
  | BannerPopEvent
  | ChatCloseEvent
  | ChatEndEvent
  | ChatStartEvent
  | ChatOpenEvent
  | ChatMaximizeEvent
  | ChatMinimizeEvent;
