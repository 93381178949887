'use client';

import {
  deleteThriventLoggedInCookie,
  setThriventLoggedInCookie,
} from './cookieHelpers';
import { ExtraSigninRequestArgs } from './types';
import { useAuth } from './useAuth';
import { logger } from '@thrivent-web/logging-utils';
import { FC, useEffect, useRef, useState } from 'react';

/**
 * The SessionMonitor uses Draft 10 (supported by forgerock) to periodically
 * make calls to renew the session if one exists.
 *
 * The interval is configurable and only runs if an active session exists.
 *
 * If a session is terminated by this or another app, the check session call
 * will fail and user information will be cleared from storage for this app.
 *
 * If user is on a page that requires authentication, the user will be signed
 * out and redirected to the sign out url specific in the oauth config.
 *
 * The session monitor performs initialization and cleanup for legacy
 * thriventLoggedIn cookie for use in legacy java apps and Brightspot content.
 */
export const SessionMonitor: FC<{
  redirectOnEndSession: boolean;
  redirectOnNoSession?: boolean;
  disabled: boolean;
  sessionCheckIntervalMs?: number;
  signinArgs?: ExtraSigninRequestArgs;
}> = ({
  redirectOnEndSession,
  redirectOnNoSession = false,
  disabled,
  sessionCheckIntervalMs = 60_000,
  signinArgs,
}) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  /**
   * Silently check for active session on first mount.
   *
   * Session may have been activated by SSR page or another web app.
   */
  useEffect(() => {
    if (disabled || auth.isLoading) return;
    if (hasTriedSignin) return;
    auth
      .signinSilent(signinArgs)
      .then((user) => {
        if (!user) {
          deleteThriventLoggedInCookie();
          auth.removeUser();
          if (redirectOnNoSession) {
            auth.signinRedirect();
          }
        } else {
          setThriventLoggedInCookie();
        }
      })
      .catch((error) => {
        logger.debug('Authentication Library | SessionMonitor', error);
        deleteThriventLoggedInCookie();
        auth.removeUser();
      })
      .finally(() => {
        setHasTriedSignin(true);
      });
  }, [auth, disabled, hasTriedSignin, redirectOnNoSession, signinArgs]);

  /**
   * Unless disabled by component prop, periodically check session status if user is authenticated.
   *
   * If session status check fails, perform all cleanup tasks required to end local session.
   */
  useEffect(() => {
    if (disabled) return;
    if (intervalId.current) {
      // TODO: find a way to test this.
      clearInterval(intervalId.current);
    }
    if (auth.isAuthenticated) {
      intervalId.current = setInterval(() => {
        // don't run check if already in progress through some other process
        if (auth.isLoading) return;
        Promise.resolve()
          .then(() => auth.querySessionStatus(signinArgs))
          .then(() => {
            setThriventLoggedInCookie();
          })
          .catch(() => {
            deleteThriventLoggedInCookie();
            clearInterval(intervalId.current);
            if (redirectOnEndSession) {
              auth.signinRedirect();
            } else {
              auth.removeUser();
            }
          });
      }, sessionCheckIntervalMs);
    }

    return () => {
      // TODO: find a way to test this.
      clearInterval(intervalId.current);
    };
  }, [
    auth,
    auth.isAuthenticated,
    disabled,
    redirectOnEndSession,
    sessionCheckIntervalMs,
    signinArgs,
  ]);

  return null;
};
