import { LiveChatVariants } from '../types';
import { Tracking, useAnalyticsTracking } from '@thrivent-web/analytics';

// --------------------------------------------------------------------------

/**
 * useLiveChatAnalytics
 *
 * Hook that returns 2 analytics tracking event functions
 */
export const useLiveChatAnalytics = () => {
  const { trackEvent } = useAnalyticsTracking();

  // Used for any of the automatic script events
  const trackAnalyticsLiveChatNotificationEvent = ({
    type,
  }: {
    type: LiveChatVariants;
  }) => {
    trackEvent({
      eventType: Tracking.notification,
      eventInfo: {
        notificationName: 'chat',
        notificationVariant: type,
      },
    });
  };

  // Used for any events that are caused from some type of user interaction
  const trackAnalyticsLiveChatInteractionEvent = ({
    type,
    duration,
  }: {
    type:
      | Tracking.chatStart
      | Tracking.chatEnd
      | Tracking.chatOpen
      | Tracking.chatClose
      | Tracking.chatMaximize
      | Tracking.chatMinimize;
    duration?: number | null;
  }) => {
    trackEvent({
      eventType: type,
      eventInfo: {
        duration: duration ?? null,
      },
    });
  };

  return {
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
  };
};
