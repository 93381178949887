import { logger } from '@thrivent-web/logging-utils';

// --------------------------------------------------------------------------

/**
 * checkBusinessHours
 *
 * Calls to SalesForce Chat API to determine if we are within the allotted business hours
 *
 * @returns Promise<boolean> - true: within business hours | false: outside of business hours
 */
export const checkBusinessHours = async () => {
  try {
    const url = `${
      process.env['NEXT_PUBLIC_SF_LIVE_CHAT_SCRT2URL'] as string
    }/embeddedservice/v1/businesshours?orgId=${
      process.env['NEXT_PUBLIC_SF_LIVE_CHAT_ORGANIZATION_ID'] as string
    }&esConfigName=${process.env['NEXT_PUBLIC_SF_LIVE_CHAT_NAME'] as string}`;
    const response = await fetch(url);
    const status = response.status;

    // Bail TRUE on empty response: no hours set in SF
    if (status === 204) {
      return true;
    }

    // Bail on bad call
    if (status !== 200) {
      logger.error('Business Hours call failed', response);
      return false;
    }

    // Get response, bail with bad data
    const businessHoursResponse = await response.json();
    const businessHours =
      businessHoursResponse?.businessHoursInfo?.businessHours;
    if (
      !businessHours ||
      businessHours.length !== 2 ||
      !businessHours[0]?.startTime ||
      !businessHours[0]?.endTime
    ) {
      logger.error(
        'Business Hours call failed with bad data',
        businessHoursResponse,
      );
      return false;
    }

    // Bail if active checkbox is not checked (manual shutoff)
    const isActive = businessHoursResponse?.businessHoursInfo?.isActive;
    if (!isActive) {
      return false;
    }

    // Get current Time
    const currentTime = Date.now();

    // Check if we're within bounds for business hours
    const activeBusinessHours =
      currentTime >= businessHours[0].startTime &&
      currentTime <= businessHours[0].endTime;

    return activeBusinessHours;
  } catch (e) {
    logger.error('Business Hours call failed with caught error', e);
    return false;
  }
};
