'use client';

import { useAuth } from './useAuth';
import { hasAuthParams } from './utils/hasAuthParams';
import { ComponentType, useEffect, useState } from 'react';

/**
 * Higher order component for use with client rendered pages where page should
 * not render until authenticated state of a user can be determined in order to
 * conditionally render content.
 *
 * Use of this HOC will prevent server rendering.
 *
 * @param Component
 * @returns Component
 */
export const withOptionalAuth = (Component: ComponentType) => {
  const AuthOptionalWrappedComponent = (props: object) => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
      if (
        !hasAuthParams() &&
        !auth.activeNavigator &&
        !auth.isLoading &&
        !hasTriedSignin
      ) {
        auth
          .querySessionStatus()
          .catch(() => auth.removeUser())
          .finally(() => {
            setHasTriedSignin(true);
          });
      }
    }, [auth, hasTriedSignin]);

    if (hasTriedSignin) {
      return <Component {...props} />;
    }

    return null;
  };

  return AuthOptionalWrappedComponent;
};
