import { LiveChatWindow } from '../types';
import { logger } from '@thrivent-web/logging-utils';

// --------------------------------------------------------------------------

/**
 * initEmbeddedMessaging
 *
 * Initializes the SalesForce Live Chat
 *
 * @returns boolean
 */
export const initEmbeddedMessaging = () => {
  try {
    const liveChatWindow = window as LiveChatWindow;
    if (liveChatWindow?.embeddedservice_bootstrap?.settings) {
      liveChatWindow.embeddedservice_bootstrap.settings.language = 'en_US';
      liveChatWindow.embeddedservice_bootstrap.settings.hideChatButtonOnLoad =
        true;
      liveChatWindow.embeddedservice_bootstrap?.init(
        process.env['NEXT_PUBLIC_SF_LIVE_CHAT_ORGANIZATION_ID'] as string,
        process.env['NEXT_PUBLIC_SF_LIVE_CHAT_NAME'] as string,
        process.env['NEXT_PUBLIC_SF_LIVE_CHAT_SITE_URL'] as string,
        {
          scrt2URL: process.env['NEXT_PUBLIC_SF_LIVE_CHAT_SCRT2URL'] as string,
        },
      );
    }
    return true;
  } catch (err) {
    logger.error('Error loading Embedded Messaging: ', err);
    return false;
  }
};
