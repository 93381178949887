'use client';

import { TrackingEvent } from '@analytics/shared';
import { datadogRum } from '@datadog/browser-rum';
import { logger } from '@thrivent-web/logging-utils';

export const datadogDispatcher = (trackingEvent: TrackingEvent) => {
  try {
    datadogRum.addAction(trackingEvent.eventType, trackingEvent);
  } catch (err) {
    logger.warn(
      'There was an error dispatching analytics event to Datadog',
      err,
    );
  }
};
