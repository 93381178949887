'use client';

import {
  type DatadogRumUser,
  setDefaultRumGlobalContext,
  setGlobalContextFeatureFlags,
  setRumUser,
} from './initializeDatadogClientUtils';
import { useEffect } from 'react';

export interface InitializeDatadogProps {
  rumUser?: DatadogRumUser;
  featureFlags?: Record<string, string | number | boolean>;
}

/**
 * Initialize Datadog RUM and Logs
 *
 * The initialization code must run in a client component so RUM can collect
 * telemetry from the client. You can achieve this without making your
 * layout.tsx file itself a client component by rendering the
 * <InitializeDatadog /> component in your layout.tsx or _app.tsx file.
 *
 * @param props.rumUser Optional user object to set as the RUM user
 * @param props.featureFlags Optional list of feature flags to pass to
 *  RUM for better context around actions collected in relationship to
 *  feature flags
 * @returns React.ReactNode
 */
export function InitializeDatadogComponent({
  rumUser,
  featureFlags,
}: InitializeDatadogProps): React.ReactNode {
  useEffect(() => {
    setDefaultRumGlobalContext();
  }, []);

  useEffect(() => {
    if (rumUser !== undefined) {
      setRumUser({
        ...rumUser,

        // When name and e-mail are not provided, use the id as the name
        // This fixes a bug in Datadog where certain features like segments are indexed on e-mail
        name: rumUser.name || `Unidentified User ${rumUser.id}`,
        email: rumUser.email || `Unidentified Email ${rumUser.id}`,
      });
    }
  }, [rumUser]);

  useEffect(() => {
    if (featureFlags !== undefined) {
      setGlobalContextFeatureFlags(featureFlags);
    }
  }, [featureFlags]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
