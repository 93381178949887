import { colors } from './colors';

export type ShadowTypes = {
  sm: string;
  smHover: string;
  md: string;
  mdHover: string;
  lg: string;
  lgHover: string;
};

export const shadow: ShadowTypes = {
  sm: `0px 2px 6px 0px ${colors.semanticColorShadowWeak}`,
  smHover: `0px 4px 6px 0px ${colors.semanticColorShadowWeak}`,
  md: `0px 2px 6px 0px ${colors.semanticColorShadowMedium}`,
  mdHover: `0px 4px 8px 0px ${colors.semanticColorShadowMedium}`,
  lg: `0px 2px 10px 0px ${colors.semanticColorShadowStrong}`,
  lgHover: `0px 4px 10px 0px ${colors.semanticColorShadowStrong}`,
} as const;
