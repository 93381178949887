import * as tokens from './generatedTokens';

export type SpacingTypes = {
  xxxsm: string;
  xxsm: string;
  xsm: string;
  sm: string;
  md: string;
  lg: string;
  xlg: string;
  xxlg: string;
  xxxlg: string;
  xxxxlg: string;
  xxxxxlg: string;
  xxxxxxlg: string;
  buttonPadding: string;
  containerPaddingSm: string;
  containerPaddingMd: string;
  containerPaddingLg: string;
  componentChipPaddingSm: string;
  componentChipPaddingMd: string;
  componentChipPaddingLg: string;
};

export type SpacingTypesNumbers = {
  xxxsm: number;
  xxsm: number;
  xsm: number;
  sm: number;
  md: number;
  lg: number;
  xlg: number;
  xxlg: number;
  xxxlg: number;
  xxxxlg: number;
  xxxxxlg: number;
  xxxxxxlg: number;
  buttonPadding: number;
  containerPaddingSm: number;
  containerPaddingMd: number;
  containerPaddingLg: number;
  componentChipPaddingSm: number;
  componentChipPaddingMd: number;
  componentChipPaddingLg: number;
};

export const spacing: SpacingTypes = {
  xxxsm: `${tokens.baseSpacingXxxsm}px`,
  xxsm: `${tokens.baseSpacingXxsm}px`,
  xsm: `${tokens.baseSpacingXsm}px`,
  sm: `${tokens.baseSpacingSm}px`,
  md: `${tokens.baseSpacingMd}px`,
  lg: `${tokens.baseSpacingLg}px`,
  xlg: `${tokens.baseSpacingXlg}px`,
  xxlg: `${tokens.baseSpacingXxlg}px`,
  xxxlg: `${tokens.baseSpacingXxxlg}px`,
  xxxxlg: `${tokens.baseSpacingXxxxlg}px`,
  xxxxxlg: `${tokens.baseSpacingXxxxxlg}px`,
  xxxxxxlg: `${tokens.baseSpacingXxxxxxlg}px`,
  buttonPadding: `${tokens.componentButtonPaddingDefault}px`,
  containerPaddingSm: `${tokens.componentContainerPaddingSm}px`,
  containerPaddingMd: `${tokens.componentContainerPaddingMd}px`,
  containerPaddingLg: `${tokens.componentContainerPaddingLg}px`,
  componentChipPaddingSm: `${tokens.componentChipPaddingSm}px`,
  componentChipPaddingMd: `${tokens.componentChipPaddingMd}px`,
  componentChipPaddingLg: `${tokens.componentChipPaddingLg}px`,
} as const;

export const spacingValues: SpacingTypesNumbers = {
  xxxsm: tokens.baseSpacingXxxsm,
  xxsm: tokens.baseSpacingXxsm,
  xsm: tokens.baseSpacingXsm,
  sm: tokens.baseSpacingSm,
  md: tokens.baseSpacingMd,
  lg: tokens.baseSpacingLg,
  xlg: tokens.baseSpacingXlg,
  xxlg: tokens.baseSpacingXxlg,
  xxxlg: tokens.baseSpacingXxxlg,
  xxxxlg: tokens.baseSpacingXxxxlg,
  xxxxxlg: tokens.baseSpacingXxxxxlg,
  xxxxxxlg: tokens.baseSpacingXxxxxxlg,
  buttonPadding: tokens.componentButtonPaddingDefault,
  containerPaddingSm: tokens.componentContainerPaddingSm,
  containerPaddingMd: tokens.componentContainerPaddingMd,
  containerPaddingLg: tokens.componentContainerPaddingLg,
  componentChipPaddingSm: tokens.componentChipPaddingSm,
  componentChipPaddingMd: tokens.componentChipPaddingMd,
  componentChipPaddingLg: tokens.componentChipPaddingLg,
} as const;
