'use client';

import {
  FormPayloadEventInfo,
  FormSubmissionSuccessEvent,
  Tracking,
} from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackFormSubmitSuccess = ReturnType<
  typeof useTrackFormSubmitSuccess
>;

/**
 * Custom React hook for tracking form submit attempt events.
 */
export const useTrackFormSubmitSuccess = () => {
  const { trackEvent } = useTracking<FormSubmissionSuccessEvent>({
    eventType: Tracking.formSubmitSuccess,
  });

  return (eventInfo: FormPayloadEventInfo): void => {
    trackEvent({
      eventInfo,
    });
  };
};
