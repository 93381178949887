'use client';

import { usePathname } from 'next/navigation';

function OnetrustScripts() {
  const pathname = usePathname();

  // Prevent Onetrust running in the  background iFrames used to keep session updated
  if (
    pathname?.startsWith('/authentication/callback') ||
    process.env['NEXT_PUBLIC_ONETRUST_ENABLED'] === 'false'
  ) {
    return null;
  }
  return (
    <>
      <script
        src={process.env.NEXT_PUBLIC_ONETRUST_URL || ' '}
        type="text/javascript"
        id="onetrust-script"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_ID}
        async
        defer
      ></script>
      <script async defer type="text/javascript" id="onetrust-function">
        {`function OptanonWrapper() {}`}
      </script>
    </>
  );
}

export default OnetrustScripts;
