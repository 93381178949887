'use client';

import { AuthSessionMonitor } from './AuthSessionMonitor';
import { oidcClientSettings } from './config';
import { OIDCProvider } from '@thrivent-web/authentication';
import { FC, PropsWithChildren } from 'react';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => (
  <OIDCProvider {...oidcClientSettings}>
    <AuthSessionMonitor />
    {children}
  </OIDCProvider>
);
