'use client';

import { TrackingEvent } from '@analytics/shared';
import { ReactElement, useCallback } from 'react';
import { useTracking } from 'react-tracking';

type DispatcherFunction = (data: TrackingEvent) => void;

type AnalyticsProviderProps = {
  children: ReactElement | ReactElement[];
  dispatchers: DispatcherFunction[];
};

/**
 * Sets up tracking analytics events for an application.
 *
 * @param props -  props for TrackUserAnalytics.
 * @param props.children - children components to be wrapped
 * @param props.dispatchers - dispatchers functions for tracking events, see @adobe/dispatchers for options
 * @returns - components wrapped for access to tracking analytics
 */
export function TrackUserAnalytics({
  children,
  dispatchers,
}: AnalyticsProviderProps): ReactElement {
  const dispatch = useCallback(
    (event: TrackingEvent) => {
      // Loop through all dispatchers and call them with the event
      dispatchers.forEach((dispatcher) => {
        dispatcher(event);
      });
    },
    [dispatchers],
  );

  // Note: All use of useTracking below this component will make use of the same dispatch function, per react-tracking docs
  const { Track } = useTracking<TrackingEvent>(
    {},
    {
      dispatch,
    },
  );

  return <Track>{children}</Track>;
}
