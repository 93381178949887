/**
 * Do not edit directly
 * Generated on Tue, 08 Oct 2024 17:38:11 GMT
 */

export const componentButtonRadiusDefault = 6;
export const componentButtonColorBackgroundStrongPrimaryDefault = '#1c4259';
export const componentButtonColorBackgroundStrongPrimaryHover = '#49687A';
export const componentButtonColorBackgroundStrongPrimaryPressed = '#203437';
export const componentButtonColorBackgroundStrongSecondaryDefault = '#F9EEDB';
export const componentButtonColorBackgroundStrongSecondaryHover = '#FEFBF6';
export const componentButtonColorBackgroundStrongSecondaryPressed = '#E5D8C2';
export const componentButtonColorBackgroundStrongDestructiveDefault = '#BE1D3D';
export const componentButtonColorBackgroundStrongDestructiveHover = '#87152B';
export const componentButtonColorBackgroundStrongDestructivePressed = '#691022';
export const componentButtonColorBackgroundSubtleDefault = '#FFFFFF';
export const componentButtonColorBackgroundSubtlePrimaryHover = '#E8ECEE';
export const componentButtonColorBackgroundSubtlePrimaryPressed = '#D1E2E4';
export const componentButtonColorBackgroundSubtleSecondaryHover = '#FEFBF6';
export const componentButtonColorBackgroundSubtleSecondaryPressed = '#E5D8C2';
export const componentButtonColorBackgroundSubtleDestructiveHover = 'hotpink';
export const componentButtonColorBackgroundSubtleDestructivePressed = 'hotpink';
export const componentButtonColorBorderSubtlePrimaryDefault = '#9BBFC4';
export const componentButtonColorBorderSubtlePrimaryHover = '#49687A'; // #49687A, was #2A60f1
export const componentButtonColorBorderSubtlePrimaryPressed = '#49687A';
export const componentButtonColorBorderSubtleSecondaryDefault = '#AB9774';
export const componentButtonColorBorderSubtleSecondaryHover = '#AB9774';
export const componentButtonColorBorderSubtleSecondaryPressed = '#AB9774';
export const componentButtonColorBorderSubtleDestructiveDefault = 'hotpink';
export const componentButtonColorBorderSubtleDestructiveHover = '#CB4A64';
export const componentButtonColorBorderSubtleDestructivePressed = 'hotpink';
export const componentButtonColorBorderSecondaryDefault = '#AB9774';
export const componentButtonColorTextGhostPrimary = '#1C4259';
export const componentButtonColorTextGhostDestructive = '#BE1D3D';
export const componentButtonColorTextSubtlePrimary = '#141414';
export const componentButtonColorTextSubtleSecondary = '#141414';
export const componentButtonColorTextSubtleDestructive = '#BE1D3D';
export const componentButtonColorTextStrongPrimary = '#FFFFFF';
export const componentButtonColorTextStrongSecondary = '#1C4259'; // was #141414
export const componentButtonColorTextStrongDestructive = '#FFFFFF';
export const componentButtonPaddingDefault = 16;
export const componentContainerBorderWidthDefault = 1;
export const componentContainerBorderWidthEmphasis = 2;
export const componentContainerBorderWidthFocus = 4;
export const componentContainerColorBackgroundDefault = '#FFFFFF';
export const componentContainerColorBackgroundHover = '#F5F8F9';
export const componentContainerColorBackgroundInfoEmphasis = '#49687A';
export const componentContainerColorBackgroundInfoSubtle = '#F5F8F9';
export const componentContainerColorBackgroundPositiveEmphasis = '#21DBB0';
export const componentContainerColorBackgroundPositiveSubtle = '#EDFAF7';
export const componentContainerColorBackgroundNegativeEmphasis = '#BE1D3D';
export const componentContainerColorBackgroundNegativeSubtle = '#F9E8EC';
export const componentContainerColorBackgroundWarningEmphasis = 'hotpink';
export const componentContainerColorBackgroundWarningSubtle = 'hotpink';
export const componentContainerColorTextHeadingSubtle = '#141414';
export const componentContainerColorTextHeadingInfoEmphasis = '#FFFFFF';
export const componentContainerColorTextHeadingNegativeEmphasis = '#FFFFFF';
export const componentContainerColorTextHeadingPositiveEmphasis = '#141414';
export const componentContainerColorTextHeadingWarningEmphasis = '#141414';
export const componentContainerColorTextParagraphSubtle = '#363636';
export const componentContainerColorTextParagraphInfoEmphasis = '#FFFFFF';
export const componentContainerColorTextParagraphNegativeEmphasis = '#FFFFFF';
export const componentContainerColorTextParagraphPositiveEmphasis = '#141414';
export const componentContainerColorTextParagraphWarningEmphasis = '#141414';
export const componentContainerColorBorderDefault = '#C7C7CA';
export const componentContainerColorBorderInfo = '#49687A';
export const componentContainerColorBorderPositive = '#137A5F';
export const componentContainerColorBorderNegative = '#BE1D3D';
export const componentContainerColorBorderWarning = 'hotpink';
export const componentContainerOpacityOverlay = 0.5;
export const componentContainerPaddingSm = 8;
export const componentContainerPaddingMd = 16;
export const componentContainerPaddingLg = 24;
export const componentContainerRadiusDefault = 6;
export const componentFormRadiusDefault = 4;
export const componentFormColorTextLabelDefault = '#686B6B';
export const componentFormColorTextLabelHover = '#575757';
export const componentFormColorTextLabelActive = '#9BBFC4';
export const componentFormColorTextInvalid = '#BE1D3D';
export const componentFormColorTextDisabled = '#A8ABAB';
export const componentFormColorTextPlaceholder = '#686B6B';
export const componentFormColorTextInput = '#141414';
export const componentFormColorBackgroundDefault = '#FFFFFF';
export const componentFormColorBackgroundDisabled = '#FAFAFA';
export const componentFormColorBackgroundReadonly = '#FAFAFA';
export const componentFormColorBorderDefault = '#686B6B';
export const componentFormColorBorderDisabled = '#F0F0F0';
export const componentFormColorBorderFocus = '#1C4259';
export const componentFormColorBorderActive = '#9BBFC4';
export const componentFormColorBorderHover = '#000000';
export const componentLinkDefault = '#49687A';
export const componentLinkHover = '#9BBFC4';
export const componentLinkVisited = '#5744A6';
export const componentTableBackgroundDefault = '#FFFFFF';
export const componentTableBackgroundHover = '#E8ECEE';
export const componentTableBackgroundChecked = '#E8ECEE';
export const componentTableBackgroundCheckedHover = 'hotpink';
export const componentTableBackgroundDefault2 = '#FFFFFF';
export const componentTableBackgroundHover2 = '#FAFAFA';
export const componentTableBackgroundChecked2 = '#FAFAFA';
export const componentTableBackgroundCheckedHover2 = '#F0F0F0';
export const componentChipRadiusDefault = 4;
export const componentChipRadiusFocus = 6;
export const componentChipColorBackgroundSelectedDefault = '#19398C';
export const componentChipColorBackgroundSelectedHover = '#2453CC';
export const componentChipColorBackgroundUnselectedDefault = '#FFFFFF';
export const componentChipColorBackgroundUnselectedHover = '#F4F7FF';
export const componentChipColorBorderUnselected = '#19398C';
export const componentChipColorTextSelected = '#FFFFFF';
export const componentChipColorTextUnselected = '#19398C';
export const componentChipColorIconSelected = '#FFFFFF';
export const componentChipColorIconUnselected = '#19398C';
export const componentChipPaddingSm = 2;
export const componentChipPaddingMd = 4;
export const componentChipPaddingLg = 8;

// --------------------------------------------------------------------------

export const baseColorWhite = '#FFFFFF';
export const baseColorBlack = '#000000';

export const baseColorBlue2 = '#E8ECEE'; // #E8ECEE, was #F4F7FF
export const baseColorBlue3 = '#F5F8F9'; // #B9C4CC, was #E9F0FF ---> #F5F8F9 (shifted off scale)
export const baseColorBlue6 = '#D7E5E7'; // #D7E5E7, was #BFCFFF ---> #D7E5E7 (shifted off scale)
export const baseColorBlue9 = '#9BBFC4'; // #9BBFC4, was #2E66FF ---> #9BBFC4 (shifted off scale)
export const baseColorBlue11 = '#49687A'; // #49687A, was #2453CC or #2A60f1
export const baseColorBlue13 = '#1C4259'; // #1C4259, was #19398C -- PRIMARY
export const baseColorBlue15 = '#203437'; // #142F3F, was #0B1C45 ---> #203437 (shifted off scale)

export const baseColorRed3 = '#F9E8EC'; // #F9E8EC, was #FDEAED
export const baseColorRed9 = '#CB4A64'; // #CB4A64, #E30045
export const baseColorRed11 = '#BE1D3D'; // #BE1D3D, was #B50737 -- PRIMARY
export const baseColorRed13 = '#87152B'; // #87152B, was #7D0B27
export const baseColorRed15 = '#500C1A'; // #500C1A, was #3E0814

// NOTE: Not confident Gold3 good "Home" for this color, its a bit darker than the other scales.
// Ideally we'd add an identityBrandTertiaryWeak for it instead, and it would be Gold5
export const baseColorGold3 = '#F9EEDB'; // #F9EEDB, was #FAF7F2

export const baseColorGold4 = '#FEFBF6'; // #FEFBF6, was #F8F5EF
export const baseColorGold5 = '#FBF2E3'; // #FBF2E3, was #F1EADC
export const baseColorGold7 = '#E5D8C2'; // #F6E2C2, was #E5D8C2
export const baseColorGold9 = '#F1D4A4'; // #F1D4A4, was #C7AB75
export const baseColorGold11 = '#AB9774'; // #AB9774, was #9F895E
export const baseColorGold13 = '#655945'; // #655945, was #6E5E41

export const baseColorPurple11 = '#5744A6'; // #5744A6 (visited)

export const baseColorGray1 = '#FFFFFF'; // #FFFFFF
export const baseColorGray2 = '#FAFAFA'; // #FAFAFA
export const baseColorGray3 = '#F2F2F2'; // #F2F2F2
export const baseColorGray4 = '#F0F0F0'; // #F0F0F0
export const baseColorGray5 = '#E0E0E0'; // #E0E0E0
export const baseColorGray6 = '#D9D9D9'; // #D9D9D9
export const baseColorGray7 = '#C7C7CA'; // #C7C7CA
export const baseColorGray8 = '#A8ABAB'; // #A8ABAB
export const baseColorGray9 = '#848587'; // #848587
export const baseColorGray10 = '#7D7D80'; // #7D7D80
export const baseColorGray11 = '#686B6B'; // #686B6B
export const baseColorGray12 = '#575757'; // #575757
export const baseColorGray13 = '#4A4A4A'; // #4A4A4A
export const baseColorGray14 = '#363636'; // #363636
export const baseColorGray15 = '#141414'; // #141414

// --------------------------------------------------------------------------

export const baseColorGreen1 = '#FCFFFF';
export const baseColorGreen2 = '#F6FDFA';
export const baseColorGreen3 = '#EDFAF7';
export const baseColorGreen4 = '#EAFAF5';
export const baseColorGreen5 = '#D2F6EA';
export const baseColorGreen6 = '#C9F4E8';
export const baseColorGreen7 = '#ACEFDA';
export const baseColorGreen8 = '#7DE5C8';
export const baseColorGreen9 = '#21DBB0';
export const baseColorGreen10 = '#1ACFA6';
export const baseColorGreen11 = '#20B08D';
export const baseColorGreen12 = '#168E71';
export const baseColorGreen13 = '#137A5F';
export const baseColorGreen14 = '#115846';
export const baseColorGreen15 = '#0C3128';

// --------------------------------------------------------------------------

export const baseColorYellow3 = 'hotpink'; // #FFFAEC
export const baseColorYellow6 = 'hotpink'; // #FFECC5
export const baseColorYellow9 = 'hotpink'; // #FFC533
export const baseColorYellow11 = 'hotpink'; // #D79D14
export const baseColorYellow13 = 'hotpink'; // #B17D02
export const baseColorYellow15 = 'hotpink'; // #453511

// --------------------------------------------------------------------------

export const baseColorYellow1 = 'hotpink';
export const baseColorYellow2 = 'hotpink';
export const baseColorYellow4 = 'hotpink';
export const baseColorYellow5 = 'hotpink';
export const baseColorYellow7 = 'hotpink';
export const baseColorYellow8 = 'hotpink';
export const baseColorYellow10 = 'hotpink';
export const baseColorYellow12 = 'hotpink';
export const baseColorYellow14 = 'hotpink';
export const baseColorGold1 = 'hotpink';
export const baseColorGold2 = 'hotpink';
export const baseColorGold6 = 'hotpink';
export const baseColorGold8 = 'hotpink';
export const baseColorGold10 = 'hotpink';
export const baseColorGold12 = 'hotpink';
export const baseColorGold14 = 'hotpink';
export const baseColorGold15 = 'hotpink';
export const baseColorPurple1 = 'hotpink';
export const baseColorPurple2 = 'hotpink';
export const baseColorPurple3 = 'hotpink';
export const baseColorPurple4 = 'hotpink';
export const baseColorPurple5 = 'hotpink';
export const baseColorPurple6 = 'hotpink';
export const baseColorPurple7 = 'hotpink';
export const baseColorPurple8 = 'hotpink';
export const baseColorPurple9 = 'hotpink';
export const baseColorPurple10 = 'hotpink';
export const baseColorPurple12 = 'hotpink';
export const baseColorPurple13 = 'hotpink';
export const baseColorPurple14 = 'hotpink';
export const baseColorPurple15 = 'hotpink';
export const baseColorMcOrange1 = 'hotpink';
export const baseColorMcOrange2 = 'hotpink';
export const baseColorMcOrange3 = 'hotpink';
export const baseColorMcOrange4 = 'hotpink';
export const baseColorMcOrange5 = 'hotpink';
export const baseColorMcOrange6 = 'hotpink';
export const baseColorMcOrange7 = 'hotpink';
export const baseColorMcOrange8 = 'hotpink';
export const baseColorMcOrange9 = 'hotpink';
export const baseColorMcOrange10 = 'hotpink';
export const baseColorMcOrange11 = 'hotpink';
export const baseColorMcOrange12 = 'hotpink';
export const baseColorMcOrange13 = 'hotpink';
export const baseColorMcOrange14 = 'hotpink';
export const baseColorMcOrange15 = 'hotpink';
export const baseColorMcBlue1 = 'hotpink';
export const baseColorMcBlue2 = 'hotpink';
export const baseColorMcBlue3 = 'hotpink';
export const baseColorMcBlue4 = 'hotpink';
export const baseColorMcBlue5 = 'hotpink';
export const baseColorMcBlue6 = 'hotpink';
export const baseColorMcBlue7 = 'hotpink';
export const baseColorMcBlue8 = 'hotpink';
export const baseColorMcBlue9 = 'hotpink';
export const baseColorMcBlue10 = 'hotpink';
export const baseColorMcBlue11 = 'hotpink';
export const baseColorMcBlue12 = 'hotpink';
export const baseColorMcBlue13 = 'hotpink';
export const baseColorMcBlue14 = 'hotpink';
export const baseColorMcBlue15 = 'hotpink';

export const baseBorderRadiusSm = 4;
export const baseBorderRadiusMd = 6;
export const baseBorderRadiusLg = 8;
export const baseBorderRadiusFull = 1000;
export const baseBorderWidthSm = 1;
export const baseBorderWidthMd = 2;
export const baseBorderWidthLg = 4;
export const baseRemXs01 = 0.125;
export const baseRemXs02 = 0.25;
export const baseRemXs03 = 0.375;
export const baseRemXs04 = 0.42500001192092896;
export const baseRemS01 = 0.5;
export const baseRemS02 = 0.625;
export const baseRemS03 = 0.75;
export const baseRemS04 = 0.875;
export const baseRemM01 = 1;
export const baseRemM02 = 1.125;
export const baseRemM03 = 1.25;
export const baseRemM04 = 1.375;
export const baseRemL01 = 1.5;
export const baseRemL02 = 1.75;
export const baseRemL03 = 2;
export const baseRemL04 = 2.25;
export const baseRemXl01 = 2.625;
export const baseRemXl02 = 3;
export const baseRemXl03 = 3.375;
export const baseRemXl04 = 3.75;
export const baseRemXxl01 = 4.25;
export const baseRemXxl02 = 4.75;
export const baseRemXxl03 = 5.25;
export const baseRemXxl04 = 6;
export const baseFontWeightHeavy = 700;
export const baseFontWeightSemi = 600;
export const baseFontWeightMedium = 500;
export const baseFontWeightRegular = 400;
export const baseSpacingXxxsm = 2;
export const baseSpacingXxsm = 4;
export const baseSpacingXsm = 8;
export const baseSpacingSm = 12;
export const baseSpacingMd = 16;
export const baseSpacingLg = 20;
export const baseSpacingXlg = 24;
export const baseSpacingXxlg = 32;
export const baseSpacingXxxlg = 40;
export const baseSpacingXxxxlg = 48;
export const baseSpacingXxxxxlg = 56;
export const baseSpacingXxxxxxlg = 64;
export const baseOpacityOverlay = 0.5;
export const baseBaseSize = 16;
export const baseLineHeightXxxxs = 12;
export const baseLineHeightXxxs = 14;
export const baseLineHeightXxs = 16;
export const baseLineHeightXs = 18;
export const baseLineHeightS = 20;
export const baseLineHeightM = 22;
export const baseLineHeightBodyMini = 14;
export const baseLineHeightBodyDefault = 16;
export const baseLineHeightL = 24;
export const baseLineHeightXl = 28;
export const baseLineHeightXxl = 32;
export const baseLineHeightXxxl = 36;
export const baseLineHeightXxxxl = 42;
export const baseLineHeightXxxxxl = 48;
export const baseLineHeightXxxxxxl = 52;
export const baseLineHeightXxxxxxxl = 72;
export const baseFontFamilyPrimary = "'BasisGrt', sans-serif";
export const baseFontFamilySecondary = "'Burgess', serif";
export const baseFontFamilyLofi = "'Comic Neue', cursive";
export const baseFontFamilyLofiAlt = "'Redacted Script', cursive";
export const baseFontSize1 = 12;
export const baseFontSize2 = 14;
export const baseFontSize3 = 16;
export const baseFontSize4 = 18;
export const baseFontSize5 = 20;
export const baseFontSize6 = 22;
export const baseFontSize7 = 24;
export const baseFontSize8 = 28;
export const baseFontSize9 = 32;
export const baseFontSize10 = 36;
export const baseFontSize11 = 40;
export const baseFontSize12 = 48;
export const baseFontSize13 = 54;
export const baseFontSize14 = 60;
export const baseFontSize15 = 68;
export const baseFontSize16 = 76;
export const baseFontSize17 = 84;
export const baseFontSize18 = 96;
export const baseFontSizeMini = 10;

export const semanticColorShadowWeak = '#D9D9D9';
export const semanticColorShadowMedium = '#A8ABAB';
export const semanticColorShadowStrong = '#7D7D80';
export const semanticColorIdentityBrandPrimaryFaint = '#F5F8F9';
export const semanticColorIdentityBrandPrimaryWeak = '#D7E5E7';
export const semanticColorIdentityBrandPrimaryMedium = '#9BBFC4';
export const semanticColorIdentityBrandPrimaryStrong = '#49687A';
export const semanticColorIdentityBrandPrimaryHeavy = '#1C4259';
export const semanticColorIdentityBrandPrimaryDense = '#203437';
export const semanticColorIdentityBrandSecondary = '#21DBB0';
export const semanticColorIdentityBrandTertiary = '#F1D4A4';
export const semanticColorIdentityBrandTertiaryStrong = '#AB9774';
export const semanticColorIdentityBrandTertiaryHeavy = '#655945';
export const semanticColorTextPrimary = '#203437';
export const semanticColorTextSecondary = '#1C4259';
export const semanticColorTextTertiary = '#575757';
export const semanticColorTextDisabled = '#A8ABAB';
export const semanticColorTextLink = '#49687A';
export const semanticColorTextPrimaryDarkBg = '#FFFFFF';
export const semanticColorTextSecondaryDarkBg = '#F0F0F0';
export const semanticColorTextTertiaryDarkBg = '#A8ABAB';
export const semanticColorTextLinkDarkBg = 'hotpink';
export const semanticColorBackgroundPrimary = '#FFFFFF';
export const semanticColorBackgroundSecondary = '#F2F2F2';
export const semanticColorBackgroundDisabled = '#FAFAFA';
export const semanticColorBorderDefault = '#C7C7CA';
export const semanticColorBorderDisabled = '#F0F0F0';
export const semanticColorBorderFocus = '#1C4259';
export const semanticColorBorderFocusDarkbg = '#9BBFC4';
export const semanticColorBorderHover = '#363636';
export const semanticColorSentimentNegativeFaint = '#F9E8EC';
export const semanticColorSentimentNegativeWeak = 'hotpink';
export const semanticColorSentimentNegativeMedium = '#CB4A64';
export const semanticColorSentimentNegativeStrong = '#BE1D3D';
export const semanticColorSentimentNegativeHeavy = '#87152B';
export const semanticColorSentimentNegativeDense = '#500C1A';
export const semanticColorSentimentPositiveFaint = '#EDFAF7';
export const semanticColorSentimentPositiveWeak = '#C9F4E8';
export const semanticColorSentimentPositiveMedium = '#21DBB0';
export const semanticColorSentimentPositiveStrong = '#20B08D';
export const semanticColorSentimentPositiveHeavy = '#137A5F';
export const semanticColorSentimentPositiveDense = '#0C3128';
export const semanticColorSentimentWarningFaint = 'hotpink';
export const semanticColorSentimentWarningWeak = 'hotpink';
export const semanticColorSentimentWarningMedium = 'hotpink';
export const semanticColorSentimentWarningStrong = 'hotpink';
export const semanticColorSentimentWarningHeavy = 'hotpink';
export const semanticColorSentimentWarningDense = 'hotpink';
export const semanticColorSentimentInfoFaint = '#F5F8F9';
export const semanticColorSentimentInfoWeak = '#D7E5E7';
export const semanticColorSentimentInfoMedium = '#9BBFC4';
export const semanticColorSentimentInfoStrong = '#49687A';
export const semanticColorSentimentInfoHeavy = '#1C4259';
export const semanticColorSentimentInfoDense = '#203437';
export const semanticColorNeutralLightWeak = '#FFFFFF';
export const semanticColorNeutralLightMedium = '#FAFAFA';
export const semanticColorNeutralLightStrong = '#E0E0E0';
export const semanticColorNeutralLightHeavy = '#C7C7CA';
export const semanticColorNeutralDarkWeak = '#848587';
export const semanticColorNeutralDarkMedium = '#575757';
export const semanticColorNeutralDarkStrong = '#363636';
export const semanticColorNeutralDarkHeavy = '#141414';
export const semanticColorDisabledOpacity = '30';
