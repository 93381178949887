'use client';

import {
  NotificationEvent,
  NotificationPayload,
  Tracking,
} from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackNotification = ReturnType<typeof useTrackNotification>;

/**
 * Custom React hook for tracking notification events (eg. notice of a bill that is due).
 */
export const useTrackNotification = () => {
  const { trackEvent } = useTracking<NotificationEvent>({
    eventType: Tracking.notification,
  });

  return (eventInfo: NotificationPayload['eventInfo']): void => {
    trackEvent({
      eventInfo,
    });
  };
};
