interface EmbeddedServiceBootstrap {
  settings: { language: string; hideChatButtonOnLoad: boolean };
  init: (
    organizationId: string,
    name: string,
    siteUrl: string,
    settings: object,
  ) => null;
  utilAPI: {
    launchChat: () => Promise<string>;
  };
  autoResponseAPI: {
    setAutoResponseParameters: (params: object) => null;
  };
  prechatAPI: {
    setHiddenPrechatFields: (fields: object) => null;
  };
}
export interface LiveChatWindow extends Window {
  embeddedservice_bootstrap?: EmbeddedServiceBootstrap;
}

export type LiveChatAtom = {
  isActiveBusinessHours: boolean;
  isInitialized: boolean;
  hasError: boolean;
  isLoading: boolean;
  isActive: boolean;
};

export enum LiveChatVariants {
  activeOnPageLoad = 'activeOnPageLoad',
  inactiveOnPageLoad = 'inactiveOnPageLoad',
  businessHoursStarted = 'businessHoursStarted',
  businessHoursEnded = 'businessHoursEnded',
  initializationError = 'initializationError',
  initializationSuccess = 'initializationSuccess',
}
