import { outlookSafeLinkFixBeforeSend } from './outlookSafeLinkFixBeforeSend';
import { RumInitConfiguration } from '@datadog/browser-rum';

type GetBeforeSend = () => RumInitConfiguration['beforeSend'];

const baseBeforeSends = [outlookSafeLinkFixBeforeSend];

export const getBeforeSend: GetBeforeSend = () => {
  return (event) => baseBeforeSends.every((beforeSend) => beforeSend(event));
};
