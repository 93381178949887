import { LiveChatWindow } from '../types';

// --------------------------------------------------------------------------

/**
 * sendPreChatData
 *
 * Sends analytics data to Sales Force Hidden Pre Chat Form
 * These values need to be defined and accounted for on the salesforce
 * side for them to carry over properly
 *
 * @returns void
 */
export const sendPreChatData = (utmData?: {
  utmSource: string | undefined;
  sourceUrl: string | undefined;
  visitorId: string;
}) => {
  const liveChatWindow = window as LiveChatWindow;
  liveChatWindow?.embeddedservice_bootstrap?.prechatAPI.setHiddenPrechatFields({
    visitorId: utmData?.visitorId,
    UtmSourceCode__c: utmData?.utmSource,
    SourceUrl__c: utmData?.sourceUrl,
  });
};
