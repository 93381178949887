'use client';

import { PageViewEvent, Tracking, WebPageDetails } from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackPageView = ReturnType<typeof useTrackPageView>;

/**
 * Custom React hook for tracking page view events.
 */
export const useTrackPageView = () => {
  const { trackEvent } = useTracking<PageViewEvent>({
    eventType: Tracking.pageView,
  });

  return (webPageDetails: WebPageDetails): void => {
    trackEvent({
      web: {
        webPageDetails,
      },
    });
  };
};
