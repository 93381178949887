'use client';

import {
  FormPayloadEventInfo,
  FormSubmissionAttemptEvent,
  Tracking,
} from '@analytics/shared';
import { useTracking } from 'react-tracking';

export type TrackFormSubmitAttempt = ReturnType<
  typeof useTrackFormSubmitAttempt
>;

/**
 * Custom React hook for tracking form submit attempt events.
 */
export const useTrackFormSubmitAttempt = () => {
  const { trackEvent } = useTracking<FormSubmissionAttemptEvent>({
    eventType: Tracking.formSubmitAttempt,
  });

  return (eventInfo: FormPayloadEventInfo): void => {
    trackEvent({
      eventInfo,
    });
  };
};
