'use client';

import { useAuthConfig } from './OIDCProvider';
import { Router, windowRouter } from './router';
import { useAuth } from './useAuth';
import { hasAuthParams } from './utils/hasAuthParams';
import { ComponentType, useEffect, useState } from 'react';

type Options = {
  router?: Router;
};

/**
 * Higher order component for use with client rendered pages where page should
 * not render until authenticated state of a user can be determined. If user
 * does not have current authentication session, the user shall be redirected to
 * login.
 *
 * Use of this HOC will prevent server rendering.
 *
 * @param Component
 * @returns Component
 */
export const withRequiredAuth = (
  Component: ComponentType,
  options?: Options,
) => {
  const AuthRequiredWrappedComponent = (props: object) => {
    const auth = useAuth();
    const { loginPageRoute } = useAuthConfig();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const router = options?.router ?? windowRouter;

    // automatically sign-in
    useEffect(() => {
      if (
        !hasAuthParams() &&
        !auth.activeNavigator &&
        !auth.isLoading &&
        !hasTriedSignin
      ) {
        auth
          .querySessionStatus()
          .catch(() =>
            auth
              .removeUser()
              .then(() =>
                router.push(
                  `${loginPageRoute}?returnTo=${
                    window.location.pathname + window.location.search
                  }`,
                ),
              ),
          )
          .finally(() => setHasTriedSignin(true));
      }
    }, [auth, hasTriedSignin, router, loginPageRoute]);

    if (hasTriedSignin && auth.isAuthenticated) {
      return <Component {...props} />;
    }

    return null;
  };

  return AuthRequiredWrappedComponent;
};
