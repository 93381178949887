/**
 * Do not edit directly
 * Generated on Thu, 31 Oct 2024 21:55:10 GMT
 */

function invertColor(hex: string): string {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color components
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Invert each component
  const invertedR = (255 - r).toString(16).padStart(2, '0');
  const invertedG = (255 - g).toString(16).padStart(2, '0');
  const invertedB = (255 - b).toString(16).padStart(2, '0');

  // Combine the inverted components back into a hex color
  return `#${invertedR}${invertedG}${invertedB}`;
}

export const componentButtonRadiusDefault = 6;
export const componentButtonColorBackgroundStrongPrimaryDefault =
  invertColor('#19398C');
export const componentButtonColorBackgroundStrongPrimaryHover =
  invertColor('#2453CC');
export const componentButtonColorBackgroundStrongPrimaryPressed =
  invertColor('#0B1C45');
export const componentButtonColorBackgroundStrongSecondaryDefault =
  invertColor('#F1EADC');
export const componentButtonColorBackgroundStrongSecondaryHover =
  invertColor('#F8F5EF');
export const componentButtonColorBackgroundStrongSecondaryPressed =
  invertColor('#E5D8C2');
export const componentButtonColorBackgroundStrongDestructiveDefault =
  invertColor('#B50737');
export const componentButtonColorBackgroundStrongDestructiveHover =
  invertColor('#E30045');
export const componentButtonColorBackgroundStrongDestructivePressed =
  invertColor('#7D0B27');
export const componentButtonColorBackgroundSubtleDefault =
  invertColor('#FFFFFF');
export const componentButtonColorBackgroundSubtlePrimaryHover =
  invertColor('#F4F7FF');
export const componentButtonColorBackgroundSubtlePrimaryPressed =
  invertColor('#D1DEFF');
export const componentButtonColorBackgroundSubtleSecondaryHover =
  invertColor('#F8F5EF');
export const componentButtonColorBackgroundSubtleSecondaryPressed =
  invertColor('#E5D8C2');
export const componentButtonColorBackgroundSubtleDestructiveHover =
  invertColor('#FEF4F5');
export const componentButtonColorBackgroundSubtleDestructivePressed =
  invertColor('#FEE5E8');
export const componentButtonColorBorderSecondaryDefault =
  invertColor('#9F895E');
export const componentButtonColorBorderSubtlePrimaryDefault =
  invertColor('#2E66FF');
export const componentButtonColorBorderSubtlePrimaryHover =
  invertColor('#2A60F1');
export const componentButtonColorBorderSubtlePrimaryPressed =
  invertColor('#2453CC');
export const componentButtonColorBorderSubtleSecondaryDefault =
  invertColor('#9F895E');
export const componentButtonColorBorderSubtleSecondaryHover =
  invertColor('#9F895E');
export const componentButtonColorBorderSubtleSecondaryPressed =
  invertColor('#9F895E');
export const componentButtonColorBorderSubtleDestructiveDefault =
  invertColor('#EF627E');
export const componentButtonColorBorderSubtleDestructiveHover =
  invertColor('#E30045');
export const componentButtonColorBorderSubtleDestructivePressed =
  invertColor('#D60041');
export const componentButtonColorTextSubtlePrimary = invertColor('#141414');
export const componentButtonColorTextSubtleSecondary = invertColor('#141414');
export const componentButtonColorTextSubtleDestructive = invertColor('#B50737');
export const componentButtonColorTextGhostPrimary = invertColor('#19398C');
export const componentButtonColorTextGhostDestructive = invertColor('#B50737');
export const componentButtonColorTextStrongPrimary = invertColor('#FFFFFF');
export const componentButtonColorTextStrongSecondary = invertColor('#141414');
export const componentButtonColorTextStrongDestructive = invertColor('#FFFFFF');
export const componentButtonPaddingDefault = 16;
export const componentContainerBorderWidthDefault = 1;
export const componentContainerBorderWidthEmphasis = 2;
export const componentContainerBorderWidthFocus = 4;
export const componentContainerColorBackgroundDefault = invertColor('#FFFFFF');
export const componentContainerColorBackgroundHover = invertColor('#E9F0FF');
export const componentContainerColorBackgroundInfoEmphasis =
  invertColor('#FFFFFF');
export const componentContainerColorBackgroundInfoSubtle =
  invertColor('#E9F0FF');
export const componentContainerColorBackgroundPositiveEmphasis =
  invertColor('#21DBB0');
export const componentContainerColorBackgroundPositiveSubtle =
  invertColor('#EDFAF7');
export const componentContainerColorBackgroundNegativeEmphasis =
  invertColor('#B50737');
export const componentContainerColorBackgroundNegativeSubtle =
  invertColor('#FDEAED');
export const componentContainerColorBackgroundWarningEmphasis =
  invertColor('#FFC533');
export const componentContainerColorBackgroundWarningSubtle =
  invertColor('#FFFAEC');
export const componentContainerColorTextHeadingSubtle = invertColor('#141414');
export const componentContainerColorTextHeadingEmphasisInfo =
  invertColor('#FFFFFF');
export const componentContainerColorTextHeadingEmphasisNegative =
  invertColor('#FFFFFF');
export const componentContainerColorTextHeadingEmphasisPositive =
  invertColor('#141414');
export const componentContainerColorTextHeadingEmphasisWarning =
  invertColor('#141414');
export const componentContainerColorTextHeadingInfoEmphasis =
  invertColor('#FFFFFF');
export const componentContainerColorTextHeadingNegativeEmphasis =
  invertColor('#FFFFFF');
export const componentContainerColorTextHeadingPositiveEmphasis =
  invertColor('#141414');
export const componentContainerColorTextHeadingWarningEmphasis =
  invertColor('#141414');
export const componentContainerColorTextParagraphSubtle =
  invertColor('#363636');
export const componentContainerColorTextParagraphEmphasisInfo =
  invertColor('#FFFFFF');
export const componentContainerColorTextParagraphEmphasisNegative =
  invertColor('#FFFFFF');
export const componentContainerColorTextParagraphEmphasisPositive =
  invertColor('#141414');
export const componentContainerColorTextParagraphEmphasisWarning =
  invertColor('#141414');
export const componentContainerColorTextParagraphInfoEmphasis =
  invertColor('#FFFFFF');
export const componentContainerColorTextParagraphNegativeEmphasis =
  invertColor('#FFFFFF');
export const componentContainerColorTextParagraphPositiveEmphasis =
  invertColor('#141414');
export const componentContainerColorTextParagraphWarningEmphasis =
  invertColor('#141414');
export const componentContainerColorBorderDefault = invertColor('#C7C7CA');
export const componentContainerColorBorderInfo = invertColor('#BFCFFF');
export const componentContainerColorBorderPositive = invertColor('#21DBB0');
export const componentContainerColorBorderNegative = invertColor('#F9BAC7');
export const componentContainerColorBorderWarning = invertColor('#FFC533');
export const componentContainerOpacityOverlay = 0.5;
export const componentContainerPaddingSm = 8;
export const componentContainerPaddingMd = 16;
export const componentContainerPaddingLg = 24;
export const componentContainerRadiusDefault = 6;
export const componentFormRadiusDefault = 4;
export const componentFormColorTextLabelDefault = invertColor('#686B6B');
export const componentFormColorTextLabelHover = invertColor('#575757');
export const componentFormColorTextLabelActive = invertColor('#2E66FF');
export const componentFormColorTextInvalid = invertColor('#B50737');
export const componentFormColorTextDisabled = invertColor('#A8ABAB');
export const componentFormColorTextPlaceholder = invertColor('#686B6B');
export const componentFormColorTextInput = invertColor('#141414');
export const componentFormColorBackgroundDefault = invertColor('#FFFFFF');
export const componentFormColorBackgroundDisabled = invertColor('#FAFAFA');
export const componentFormColorBackgroundReadonly = invertColor('#FAFAFA');
export const componentFormColorBorderDefault = invertColor('#686B6B');
export const componentFormColorBorderDisabled = invertColor('#F0F0F0');
export const componentFormColorBorderFocus = invertColor('#FFC533');
export const componentFormColorBorderActive = invertColor('#2E66FF');
export const componentFormColorBorderHover = invertColor('#000000');
export const componentLinkDefault = invertColor('#2453CC');
export const componentLinkHover = invertColor('#2E66FF');
export const componentLinkVisited = invertColor('#5744A6');
export const componentTableBackgroundDefault = invertColor('#FFFFFF');
export const componentTableBackgroundHover = invertColor('#F4F7FF');
export const componentTableBackgroundChecked = invertColor('#F4F7FF');
export const componentTableBackgroundCheckedHover = invertColor('#E7EDFF');
export const componentTableBackgroundDefault2 = invertColor('#FFFFFF');
export const componentTableBackgroundHover2 = invertColor('#FAFAFA');
export const componentTableBackgroundChecked2 = invertColor('#FAFAFA');
export const componentTableBackgroundCheckedHover2 = invertColor('#F0F0F0');
export const componentChipRadiusDefault = 4;
export const componentChipRadiusFocus = 0;
export const componentChipColorBackgroundSelectedDefault =
  invertColor('#085E5E');
export const componentChipColorBackgroundSelectedHover = invertColor('#042F2F');
export const componentChipColorBackgroundUnselectedDefault =
  invertColor('#FFFFFF');
export const componentChipColorBackgroundUnselectedHover =
  invertColor('#E0F5F5');
export const componentChipColorBorderUnselected = invertColor('#085E5E');
export const componentChipColorTextSelected = invertColor('#FFFFFF');
export const componentChipColorTextUnselected = invertColor('#141414');
export const componentChipColorIconSelected = invertColor('#FFFFFF');
export const componentChipColorIconUnselected = invertColor('#141414');
export const componentChipPaddingSm = 2;
export const componentChipPaddingMd = 4;
export const componentChipPaddingLg = 8;
export const baseColorWhite = invertColor('#FFFFFF');
export const baseColorBlack = invertColor('#000000');
export const baseColorBlue1 = invertColor('#FCFCFF');
export const baseColorBlue2 = invertColor('#F4F7FF');
export const baseColorBlue3 = invertColor('#E9F0FF');
export const baseColorBlue4 = invertColor('#E7EDFF');
export const baseColorBlue5 = invertColor('#D1DEFF');
export const baseColorBlue6 = invertColor('#BFCFFF');
export const baseColorBlue7 = invertColor('#A4BBFF');
export const baseColorBlue8 = invertColor('#7095FF');
export const baseColorBlue9 = invertColor('#2E66FF');
export const baseColorBlue10 = invertColor('#2A60F1');
export const baseColorBlue11 = invertColor('#2453CC');
export const baseColorBlue12 = invertColor('#1C43A7');
export const baseColorBlue13 = invertColor('#19398C');
export const baseColorBlue14 = invertColor('#152967');
export const baseColorBlue15 = invertColor('#0B1C45');
export const baseColorRed1 = invertColor('#FFFCFC');
export const baseColorRed2 = invertColor('#FEF4F5');
export const baseColorRed3 = invertColor('#FDEAED');
export const baseColorRed4 = invertColor('#FEE5E8');
export const baseColorRed5 = invertColor('#FCCFD6');
export const baseColorRed6 = invertColor('#F9BAC7');
export const baseColorRed7 = invertColor('#F79DAD');
export const baseColorRed8 = invertColor('#EF627E');
export const baseColorRed9 = invertColor('#E30045');
export const baseColorRed10 = invertColor('#D60041');
export const baseColorRed11 = invertColor('#B50737');
export const baseColorRed12 = invertColor('#94062E');
export const baseColorRed13 = invertColor('#7D0B27');
export const baseColorRed14 = invertColor('#5C081D');
export const baseColorRed15 = invertColor('#3E0814');
export const baseColorGreen1 = invertColor('#FCFFFF');
export const baseColorGreen2 = invertColor('#F6FDFA');
export const baseColorGreen3 = invertColor('#EDFAF7');
export const baseColorGreen4 = invertColor('#EAFAF5');
export const baseColorGreen5 = invertColor('#D2F6EA');
export const baseColorGreen6 = invertColor('#C9F4E8');
export const baseColorGreen7 = invertColor('#ACEFDA');
export const baseColorGreen8 = invertColor('#7DE5C8');
export const baseColorGreen9 = invertColor('#21DBB0');
export const baseColorGreen10 = invertColor('#1ACFA6');
export const baseColorGreen11 = invertColor('#20B08D');
export const baseColorGreen12 = invertColor('#168E71');
export const baseColorGreen13 = invertColor('#137A5F');
export const baseColorGreen14 = invertColor('#115846');
export const baseColorGreen15 = invertColor('#0C3128');
export const baseColorGray1 = invertColor('#FFFFFF');
export const baseColorGray2 = invertColor('#FAFAFA');
export const baseColorGray3 = invertColor('#F2F2F2');
export const baseColorGray4 = invertColor('#F0F0F0');
export const baseColorGray5 = invertColor('#E0E0E0');
export const baseColorGray6 = invertColor('#D9D9D9');
export const baseColorGray7 = invertColor('#C7C7CA');
export const baseColorGray8 = invertColor('#A8ABAB');
export const baseColorGray9 = invertColor('#848587');
export const baseColorGray10 = invertColor('#7D7D80');
export const baseColorGray11 = invertColor('#686B6B');
export const baseColorGray12 = invertColor('#575757');
export const baseColorGray13 = invertColor('#4A4A4A');
export const baseColorGray14 = invertColor('#363636');
export const baseColorGray15 = invertColor('#141414');
export const baseColorYellow1 = invertColor('#FFFFFC');
export const baseColorYellow2 = invertColor('#FFFCF7');
export const baseColorYellow3 = invertColor('#FFFAEC');
export const baseColorYellow4 = invertColor('#FFF7E9');
export const baseColorYellow5 = invertColor('#FFF2D6');
export const baseColorYellow6 = invertColor('#FFECC5');
export const baseColorYellow7 = invertColor('#FEE5AC');
export const baseColorYellow8 = invertColor('#FFD77A');
export const baseColorYellow9 = invertColor('#FFC533');
export const baseColorYellow10 = invertColor('#F1B832');
export const baseColorYellow11 = invertColor('#D79D14');
export const baseColorYellow12 = invertColor('#D29810');
export const baseColorYellow13 = invertColor('#B17D02');
export const baseColorYellow14 = invertColor('#674E16');
export const baseColorYellow15 = invertColor('#453511');
export const baseColorGold1 = invertColor('#FFFFFC');
export const baseColorGold2 = invertColor('#FDFAF7');
export const baseColorGold3 = invertColor('#FAF7F2');
export const baseColorGold4 = invertColor('#F8F5EF');
export const baseColorGold5 = invertColor('#F1EADC');
export const baseColorGold6 = invertColor('#EFE5D4');
export const baseColorGold7 = invertColor('#E5D8C2');
export const baseColorGold8 = invertColor('#D7C49F');
export const baseColorGold9 = invertColor('#C7AB75');
export const baseColorGold10 = invertColor('#BCA06F');
export const baseColorGold11 = invertColor('#9F895E');
export const baseColorGold12 = invertColor('#836F4D');
export const baseColorGold13 = invertColor('#6E5E41');
export const baseColorGold14 = invertColor('#4E452E');
export const baseColorGold15 = invertColor('#372E1F');
export const baseColorPurple1 = invertColor('#FCFCFF');
export const baseColorPurple2 = invertColor('#F7F7FD');
export const baseColorPurple3 = invertColor('#F0EDFB');
export const baseColorPurple4 = invertColor('#EEEAF9');
export const baseColorPurple5 = invertColor('#DFD9F4');
export const baseColorPurple6 = invertColor('#D2CCF0');
export const baseColorPurple7 = invertColor('#BFB2EA');
export const baseColorPurple8 = invertColor('#9C8CDE');
export const baseColorPurple9 = invertColor('#6E56CF');
export const baseColorPurple10 = invertColor('#6751C3');
export const baseColorPurple11 = invertColor('#5744A6');
export const baseColorPurple12 = invertColor('#473787');
export const baseColorPurple13 = invertColor('#3D3072');
export const baseColorPurple14 = invertColor('#2D2352');
export const baseColorPurple15 = invertColor('#1D1738');
export const baseColorMcOrange1 = invertColor('#FFFCFC');
export const baseColorMcOrange2 = invertColor('#FEF7F4');
export const baseColorMcOrange3 = invertColor('#FFEFEA');
export const baseColorMcOrange4 = invertColor('#FDEAE7');
export const baseColorMcOrange5 = invertColor('#FDDAD2');
export const baseColorMcOrange6 = invertColor('#FCCDC0');
export const baseColorMcOrange7 = invertColor('#FAB5A2');
export const baseColorMcOrange8 = invertColor('#F7896F');
export const baseColorMcOrange9 = invertColor('#F05429');
export const baseColorMcOrange10 = invertColor('#E24F26');
export const baseColorMcOrange11 = invertColor('#C14421');
export const baseColorMcOrange12 = invertColor('#9D361D');
export const baseColorMcOrange13 = invertColor('#843016');
export const baseColorMcOrange14 = invertColor('#612212');
export const baseColorMcOrange15 = invertColor('#42170C');
export const baseColorMcBlue1 = invertColor('#FCFFFF');
export const baseColorMcBlue2 = invertColor('#F7FAFF');
export const baseColorMcBlue3 = invertColor('#EBF5FF');
export const baseColorMcBlue4 = invertColor('#E9F3FF');
export const baseColorMcBlue5 = invertColor('#D4EBFE');
export const baseColorMcBlue6 = invertColor('#C4E1FF');
export const baseColorMcBlue7 = invertColor('#AAD5FF');
export const baseColorMcBlue8 = invertColor('#77BFFF');
export const baseColorMcBlue9 = invertColor('#2EA1FF');
export const baseColorMcBlue10 = invertColor('#2799EF');
export const baseColorMcBlue11 = invertColor('#2681CD');
export const baseColorMcBlue12 = invertColor('#2067A5');
export const baseColorMcBlue13 = invertColor('#1D588B');
export const baseColorMcBlue14 = invertColor('#154166');
export const baseColorMcBlue15 = invertColor('#0F2C44');
export const baseBorderRadiusSm = 4;
export const baseBorderRadiusMd = 6;
export const baseBorderRadiusLg = 8;
export const baseBorderRadiusFull = 1000;
export const baseBorderWidthSm = 1;
export const baseBorderWidthMd = 2;
export const baseBorderWidthLg = 4;
export const baseRemXs01 = 0.125;
export const baseRemXs02 = 0.25;
export const baseRemXs03 = 0.375;
export const baseRemXs04 = 0.42500001192092896;
export const baseRemS01 = 0.5;
export const baseRemS02 = 0.625;
export const baseRemS03 = 0.75;
export const baseRemS04 = 0.875;
export const baseRemM01 = 1;
export const baseRemM02 = 1.125;
export const baseRemM03 = 1.25;
export const baseRemM04 = 1.375;
export const baseRemL01 = 1.5;
export const baseRemL02 = 1.75;
export const baseRemL03 = 2;
export const baseRemL04 = 2.25;
export const baseRemXl01 = 2.625;
export const baseRemXl02 = 3;
export const baseRemXl03 = 3.375;
export const baseRemXl04 = 3.75;
export const baseRemXxl01 = 4.25;
export const baseRemXxl02 = 4.75;
export const baseRemXxl03 = 5.25;
export const baseRemXxl04 = 6;
export const baseFontWeightHeavy = 700;
export const baseFontWeightSemi = 600;
export const baseFontWeightMedium = 500;
export const baseFontWeightRegular = 400;
export const baseSpacingXxxsm = 2;
export const baseSpacingXxsm = 4;
export const baseSpacingXsm = 8;
export const baseSpacingSm = 12;
export const baseSpacingMd = 16;
export const baseSpacingLg = 20;
export const baseSpacingXlg = 24;
export const baseSpacingXxlg = 32;
export const baseSpacingXxxlg = 40;
export const baseSpacingXxxxlg = 48;
export const baseSpacingXxxxxlg = 56;
export const baseSpacingXxxxxxlg = 64;
export const baseOpacityOverlay = 0.5;
export const baseBaseSize = 16;
export const baseFontFamilyPrimary = "'Inter', sans-serif";
export const baseFontFamilySecondary = "'Libre Baskerville', serif";
export const baseFontFamilyLofi = "'Comic Neue', cursive";
export const baseFontFamilyLofiAlt = "'Redacted Script', cursive";
export const baseLineHeightXxxxs = 12;
export const baseLineHeightXxxs = 14;
export const baseLineHeightXxs = 16;
export const baseLineHeightXs = 18;
export const baseLineHeightS = 20;
export const baseLineHeightM = 22;
export const baseLineHeightBodyMini = 14;
export const baseLineHeightBodyDefault = 16;
export const baseLineHeightL = 24;
export const baseLineHeightXl = 28;
export const baseLineHeightXxl = 32;
export const baseLineHeightXxxl = 36;
export const baseLineHeightXxxxl = 42;
export const baseLineHeightXxxxxl = 48;
export const baseLineHeightXxxxxxl = 52;
export const baseLineHeightXxxxxxxl = 72;
export const baseFontSize1 = 12;
export const baseFontSize2 = 14;
export const baseFontSize3 = 16;
export const baseFontSize4 = 18;
export const baseFontSize5 = 20;
export const baseFontSize6 = 22;
export const baseFontSize7 = 24;
export const baseFontSize8 = 28;
export const baseFontSize9 = 32;
export const baseFontSize10 = 36;
export const baseFontSize11 = 40;
export const baseFontSize12 = 48;
export const baseFontSize13 = 54;
export const baseFontSize14 = 60;
export const baseFontSize15 = 68;
export const baseFontSize16 = 76;
export const baseFontSize17 = 84;
export const baseFontSize18 = 96;
export const baseFontSizeMini = 10;
export const semanticColorShadowWeak = invertColor('#D9D9D9');
export const semanticColorShadowMedium = invertColor('#A8ABAB');
export const semanticColorShadowStrong = invertColor('#7D7D80');
export const semanticColorIdentityBrandPrimaryFaint = invertColor('#E9F0FF');
export const semanticColorIdentityBrandPrimaryWeak = invertColor('#BFCFFF');
export const semanticColorIdentityBrandPrimaryMedium = invertColor('#2E66FF');
export const semanticColorIdentityBrandPrimaryStrong = invertColor('#2453CC');
export const semanticColorIdentityBrandPrimaryHeavy = invertColor('#19398C');
export const semanticColorIdentityBrandPrimaryDense = invertColor('#0B1C45');
export const semanticColorIdentityBrandSecondary = invertColor('#21DBB0');
export const semanticColorIdentityBrandTertiary = invertColor('#C7AB75');
export const semanticColorIdentityBrandTertiaryStrong = invertColor('#9F895E');
export const semanticColorIdentityBrandTertiaryHeavy = invertColor('#6E5E41');
export const semanticColorTextPrimary = invertColor('#141414');
export const semanticColorTextSecondary = invertColor('#363636');
export const semanticColorTextTertiary = invertColor('#575757');
export const semanticColorTextDisabled = invertColor('#A8ABAB');
export const semanticColorTextLink = invertColor('#2453CC');
export const semanticColorTextPrimaryDarkBg = invertColor('#FFFFFF');
export const semanticColorTextSecondaryDarkBg = invertColor('#F0F0F0');
export const semanticColorTextTertiaryDarkBg = invertColor('#A8ABAB');
export const semanticColorTextLinkDarkBg = invertColor('#A4BBFF');
export const semanticColorBackgroundPrimary = invertColor('#FFFFFF');
export const semanticColorBackgroundSecondary = invertColor('#F2F2F2');
export const semanticColorBackgroundDisabled = invertColor('#FAFAFA');
export const semanticColorBorderDefault = invertColor('#C7C7CA');
export const semanticColorBorderDisabled = invertColor('#F0F0F0');
export const semanticColorBorderFocus = invertColor('#FFC533');
export const semanticColorBorderFocusDarkbg = invertColor('#FFC533');
export const semanticColorBorderHover = invertColor('#363636');
export const semanticColorSentimentNegativeFaint = invertColor('#FDEAED');
export const semanticColorSentimentNegativeWeak = invertColor('#F9BAC7');
export const semanticColorSentimentNegativeMedium = invertColor('#E30045');
export const semanticColorSentimentNegativeStrong = invertColor('#B50737');
export const semanticColorSentimentNegativeHeavy = invertColor('#7D0B27');
export const semanticColorSentimentNegativeDense = invertColor('#3E0814');
export const semanticColorSentimentPositiveFaint = invertColor('#EDFAF7');
export const semanticColorSentimentPositiveWeak = invertColor('#C9F4E8');
export const semanticColorSentimentPositiveMedium = invertColor('#21DBB0');
export const semanticColorSentimentPositiveStrong = invertColor('#20B08D');
export const semanticColorSentimentPositiveHeavy = invertColor('#137A5F');
export const semanticColorSentimentPositiveDense = invertColor('#0C3128');
export const semanticColorSentimentWarningFaint = invertColor('#FFFAEC');
export const semanticColorSentimentWarningWeak = invertColor('#FFECC5');
export const semanticColorSentimentWarningMedium = invertColor('#FFC533');
export const semanticColorSentimentWarningStrong = invertColor('#D79D14');
export const semanticColorSentimentWarningHeavy = invertColor('#B17D02');
export const semanticColorSentimentWarningDense = invertColor('#453511');
export const semanticColorSentimentInfoFaint = invertColor('#E9F0FF');
export const semanticColorSentimentInfoWeak = invertColor('#BFCFFF');
export const semanticColorSentimentInfoMedium = invertColor('#2E66FF');
export const semanticColorSentimentInfoStrong = invertColor('#2453CC');
export const semanticColorSentimentInfoHeavy = invertColor('#19398C');
export const semanticColorSentimentInfoDense = invertColor('#0B1C45');
export const semanticColorNeutralLightWeak = invertColor('#FFFFFF');
export const semanticColorNeutralLightMedium = invertColor('#FAFAFA');
export const semanticColorNeutralLightStrong = invertColor('#E0E0E0');
export const semanticColorNeutralLightHeavy = invertColor('#C7C7CA');
export const semanticColorNeutralDarkWeak = invertColor('#848587');
export const semanticColorNeutralDarkMedium = invertColor('#575757');
export const semanticColorNeutralDarkStrong = invertColor('#363636');
export const semanticColorNeutralDarkHeavy = invertColor('#141414');
export const semanticColorDisabledOpacity = '30';
