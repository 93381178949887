export const getProcessDefaultPrivacyLevel = () => {
  const processDefaultPrivacyLevel =
    process.env['NEXT_PUBLIC_DD_DEFAULT_PRIVACY_LEVEL'];
  if (
    processDefaultPrivacyLevel !== undefined &&
    !/mask|mask-user-input/.test(processDefaultPrivacyLevel)
  ) {
    throw new Error(
      'process.env.NEXT_PUBLIC_DD_DEFAULT_PRIVACY_LEVEL must be "mask", "mask-user-input", or undefined',
    );
  }

  return processDefaultPrivacyLevel as 'mask' | 'mask-user-input' | undefined;
};
