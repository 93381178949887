'use client';

import { liveChatAtom } from './live-chat-atom';
import { LiveChatVariants, LiveChatWindow } from './types';
import { checkBusinessHours } from './utils/check-business-hours';
import { initEmbeddedMessaging } from './utils/init-embedded-messaging';
import { sendPreChatData } from './utils/send-pre-chat-data';
import { useLiveChatAnalytics } from './utils/use-live-chat-analytics';
import { Tracking } from '@thrivent-web/analytics';
import { logger } from '@thrivent-web/logging-utils';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLeadFormContextData } from 'visitor-attributes-react';

// --------------------------------------------------------------------------

export const LiveChatScript = () => {
  const {
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
  } = useLiveChatAnalytics();
  const setLiveChat = useSetAtom(liveChatAtom);
  const [chatEnded, setChatEnded] = useState(false);
  const [chatStartTime, setChatStartTime] = useState<number | null>(null);
  const { utmSourceDTM, referenceCodeURL, visitorId } =
    useLeadFormContextData();

  // useEffect for event listeners
  useEffect(() => {
    // Grabbing the utm_source and source url from the query params to pass to sendPreChatData()
    const handleEmbeddedMessagingReady = () => {
      sendPreChatData({
        utmSource: utmSourceDTM,
        sourceUrl: referenceCodeURL,
        visitorId,
      });
    };
    // This fires when initialization is complete.
    window.addEventListener(
      'onEmbeddedMessagingReady',
      handleEmbeddedMessagingReady,
    );

    // This handles all the "in iframe" events that are exposed to us
    const messageHandler = (event: MessageEvent) => {
      // This fires when we click "Start Conversation" in the iframe
      if (event?.data?.method === 'ESW_APP_PRECHAT_SUBMIT') {
        trackAnalyticsLiveChatInteractionEvent({
          type: Tracking.chatStart,
        });
        setChatStartTime(Date.now());
      }

      // This fires both when we end the conversation as well as when we click the "x"
      if (event?.data?.method === 'ESW_CLEAR_WEBSTORAGE_EVENT' && !chatEnded) {
        trackAnalyticsLiveChatInteractionEvent({
          type: Tracking.chatEnd,
          duration:
            chatStartTime && !Number.isNaN(chatStartTime)
              ? Date.now() - chatStartTime
              : null,
        });
        setChatEnded(true);
      }

      // This fires when we click the "x" and unload the iframe
      if (event?.data?.method === 'ESW_APP_RESET_INITIAL_STATE') {
        setLiveChat((prev) => ({
          ...prev,
          isActive: false,
        }));
        trackAnalyticsLiveChatInteractionEvent({
          type: Tracking.chatClose,
          duration:
            chatStartTime && !Number.isNaN(chatStartTime)
              ? Date.now() - chatStartTime
              : null,
        });
        setChatEnded(false);
      }

      // Fires when you click the floating circle to open, also on initial load
      if (event?.data?.method === 'ESW_APP_MAXIMIZE') {
        trackAnalyticsLiveChatInteractionEvent({
          type: Tracking.chatMaximize,
          duration:
            chatStartTime && !Number.isNaN(chatStartTime)
              ? Date.now() - chatStartTime
              : null,
        });
      }

      // Fires when you click the floating circle to close
      if (event?.data?.method === 'ESW_APP_MINIMIZE') {
        trackAnalyticsLiveChatInteractionEvent({
          type: Tracking.chatMinimize,
          duration:
            chatStartTime && !Number.isNaN(chatStartTime)
              ? Date.now() - chatStartTime
              : null,
        });
      }
    };
    // deepcode ignore InsufficientPostmessageValidation: This is the only way SF exposes "in chat" events
    window.addEventListener('message', messageHandler);

    // This event fires when we hit the end of a business hours session.
    // Unconfirmed: it also seems to fire on "Off days" after init
    const businessHoursEndedHandler = () => {
      setLiveChat((prev) => ({
        ...prev,
        isActiveBusinessHours: false,
      }));
      trackAnalyticsLiveChatNotificationEvent({
        type: LiveChatVariants.businessHoursEnded,
      });
    };
    window.addEventListener(
      'onEmbeddedMessagingBusinessHoursEnded',
      businessHoursEndedHandler,
    );

    // This even fires when business hours start.
    // It does not fire if we init during business hours
    const businessHoursStartedHandler = () => {
      setLiveChat((prev) => ({
        ...prev,
        isActiveBusinessHours: true,
      }));
      trackAnalyticsLiveChatNotificationEvent({
        type: LiveChatVariants.businessHoursStarted,
      });
    };
    window.addEventListener(
      'onEmbeddedMessagingBusinessHoursStarted',
      businessHoursStartedHandler,
    );

    // This event fires if anything errors out in the salesforce bootstrap.
    const initErrorHandler = () => {
      setLiveChat((prev) => ({
        ...prev,
        hasError: true,
      }));
      trackAnalyticsLiveChatNotificationEvent({
        type: LiveChatVariants.initializationError,
      });
    };
    window.addEventListener('onEmbeddedMessagingInitError', initErrorHandler);

    // This event fires when we successfully open a chat session.
    const initSuccessHandler = () => {
      setLiveChat((prev) => ({
        ...prev,
        isActive: true,
      }));
      trackAnalyticsLiveChatInteractionEvent({
        type: Tracking.chatOpen,
      });
    };
    window.addEventListener(
      'onEmbeddedMessagingInitSuccess',
      initSuccessHandler,
    );

    return () => {
      window.removeEventListener(
        'onEmbeddedMessagingReady',
        handleEmbeddedMessagingReady,
      );
      window.removeEventListener('message', messageHandler);
      window.removeEventListener(
        'onEmbeddedMessagingBusinessHoursEnded',
        businessHoursEndedHandler,
      );
      window.removeEventListener(
        'onEmbeddedMessagingBusinessHoursStarted',
        businessHoursStartedHandler,
      );
      window.removeEventListener(
        'onEmbeddedMessagingInitError',
        initErrorHandler,
      );
      window.removeEventListener(
        'onEmbeddedMessagingInitSuccess',
        initSuccessHandler,
      );
    };
  }, [
    setLiveChat,
    chatStartTime,
    setChatStartTime,
    chatEnded,
    setChatEnded,
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
    utmSourceDTM,
    referenceCodeURL,
    visitorId,
  ]);

  // useEffect for script creation
  useEffect(() => {
    const liveChatWindow = global.window as LiveChatWindow;
    // Bail if we can't show the chat, or there is another instance
    const existingScript = document.getElementById('sf-live-chat');
    if (existingScript || !!liveChatWindow.embeddedservice_bootstrap?.init) {
      return;
    }

    checkBusinessHours()
      .then((activeBusinessHours) => {
        setLiveChat((prev) => ({
          ...prev,
          isActiveBusinessHours: activeBusinessHours,
        }));
        trackAnalyticsLiveChatNotificationEvent({
          type: activeBusinessHours
            ? LiveChatVariants.activeOnPageLoad
            : LiveChatVariants.inactiveOnPageLoad,
        });
      })
      .catch((error) => {
        setLiveChat((prev) => ({
          ...prev,
          isActiveBusinessHours: false,
        }));
        trackAnalyticsLiveChatNotificationEvent({
          type: LiveChatVariants.inactiveOnPageLoad,
        });
        logger.error('Caught error on checkBusinessHours: ', error);
      });

    // This creates a script and loads the necessary init functions when applicable
    const script = document.createElement('script');
    script.src = process.env['NEXT_PUBLIC_SF_LIVE_CHAT_SCRIPT_SRC'] as string;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = 'sf-live-chat';
    script.dataset['testid'] = 'sf-live-chat-script';
    document.body.appendChild(script);
    script.onload = () => {
      const init = initEmbeddedMessaging();
      if (!init) {
        setLiveChat((prev) => ({
          ...prev,
          hasError: true,
        }));
        trackAnalyticsLiveChatNotificationEvent({
          type: LiveChatVariants.initializationError,
        });
        return;
      }
      setLiveChat((prev) => ({
        ...prev,
        isInitialized: true,
      }));
      trackAnalyticsLiveChatNotificationEvent({
        type: LiveChatVariants.initializationSuccess,
      });
    };
  }, [
    setLiveChat,
    trackAnalyticsLiveChatInteractionEvent,
    trackAnalyticsLiveChatNotificationEvent,
  ]);
  return null;
};
