import {
  border,
  breakpoints,
  colors,
  darkColors,
  primaryFont,
  radius,
  secondaryFont,
  shadow,
  spacing,
} from './typescriptTokens';
import { DefaultTheme } from 'styled-components';

export const explorationBrandRefreshTheme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  spacing: spacing,
  shadow: shadow,
  radius: radius,
  breakpoints: breakpoints,
  midwestColors: colors,
  border: border,
};

export const explorationDarkTheme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  spacing: spacing,
  shadow: shadow,
  radius: radius,
  breakpoints: breakpoints,
  midwestColors: darkColors,
  border: border,
};

export * from './typography/midwestTextStyles';

export * from './theme';
