import { LiveChatAtom } from './types';
import { atom } from 'jotai';

// --------------------------------------------------------------------------

/**
 * liveChatAtom
 *
 * Toggles the various states the salesforce script / chat can be in
 *
 * @returns PrimitiveAtom<LiveChatAtom>
 */
export const liveChatAtom = atom<LiveChatAtom>({
  isActiveBusinessHours: false,
  isInitialized: false,
  hasError: false,
  isLoading: false,
  isActive: false,
});
