/**
 * Determines if href is an authorization response
 * @param {string} [href=window.location.href] href
 * @returns boolean
 */
export const hasAuthParams = (href = window.location.href): boolean => {
  const url = new URL(href, 'http://localhost');
  // response_mode: query
  let searchParams = new URLSearchParams(url.search);
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(url.hash.replace('#', '?'));
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  return false;
};
